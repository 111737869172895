import { createSlice } from '@reduxjs/toolkit';
import { loginAsync } from '../reducer/authReducer';
import { RootState } from '../store';

interface AuthState {
    isAuthenticated: boolean;
    loaction_test:number
}

const initialState: AuthState = {
    isAuthenticated: false,
    loaction_test:0
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setTestLocation(state,action){
            console.log(action,'asdadadad');
            
            state.loaction_test = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginAsync.fulfilled, (state) =>{
            state.isAuthenticated = true;
        });
    }
})

export const selectAuth = (state: RootState) => state.auth;
export const {setTestLocation} = authSlice.actions

export default authSlice.reducer;