import { createSlice } from "@reduxjs/toolkit";
import { loginAsync } from "../reducer/authReducer";
import { RootState } from "../store";

interface profileState {
  profile: {
    name: string;
    lastName: string;
    middle_name: string;
    teacher_class: string;
    email: string;
  }
}

const initialState: profileState = {
  profile: {
    name: '',
    lastName: '',
    middle_name: '',
    teacher_class: '',
    email: '',
  }

}


const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setName(state, action) {
      state.profile.name = action.payload
    },
    setLastName(state, action) {
      state.profile.lastName = action.payload
    },
    setNameThree(state, action) {
      state.profile.middle_name = action.payload
    },
    setClass(state, action) {
      // state.profile.class = action.payload
    },
    setEmail(state, action) {
      state.profile.email = action.payload
    },
    setProfile(state, action) {
      console.log(action.payload);

      state.profile = action.payload
    }
  },
});

export const selectAuth = (state: RootState) => state.auth;
export const { setName, setLastName, setNameThree, setClass, setEmail, setProfile } = profileSlice.actions
export default profileSlice.reducer;
