import { FC } from 'react';
import classes from './VideoCard.module.scss';

interface VideoCardProps {
    id: number;
    text: string;
    onSelect: any;
    isSelected: boolean;
    questionArr?: any
    setQuestionArr?:any
    type?:any
}

export const VideoCard: FC<VideoCardProps> = ({id, text, onSelect, isSelected, questionArr, setQuestionArr,type,...props}) => {

    const handleClick = () => {
        onSelect(id);
    };

    return (
        <div className={`${classes.card} ${isSelected ? classes.selected : ''}`} onClick={handleClick}>
            <div className={classes.circle}>
            <span>{id}</span>
            </div>
            <iframe className={classes.video} src={text} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </div>
    );
}

