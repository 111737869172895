import { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import style from './TestSpartTable.module.scss';

interface DataType {
  key: string;
  title: string;
  lastUse: string;
  subject: string;
  lastChange: string;
  create: string;
}

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Фио',
    dataIndex: 'teacher',
    key: 'teacher',
  },
  // {
  //   title: 'Последнее использование',
  //   dataIndex: 'lastUse',
  //   key: 'lastUse',
  // },
  {
    title: 'Четверть',
    dataIndex: 'choise',
    key: 'choise',
  },
  {
    title: 'Загаловок',
    dataIndex: 'title',
    key: 'title',
  },
  // {
  //   title: 'Последнее изменение',
  //   dataIndex: 'lastChange',
  //   key: 'lastChange',
  // },
  // {
  //   title: 'Создан',
  //   dataIndex: 'create',
  //   key: 'create',
  // }
];

const data: DataType[] = [
  {
    key: '',
    title: 'yu',
    lastUse: '',
    subject: '',
    lastChange: '',
    create: '',
  },
];
interface Props{
  searchTerm:any,
  setSearchTerm:any
}
export const TestSpartTable: FC<Props> = ({searchTerm,setSearchTerm}) => {
    const [data, setData] = useState([])
  useEffect(() => {
    fetch('https://yiman.webtm.ru/api/v1/tests/create/', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token_teacher')}`
      }
    }).then((res) => {
      return res.json()
    }).then((data) => {
      console.log(data);

      setData(data)
    })
  }, [])
  console.log(data);
  const filteredData = data.filter((item:any) =>
    item.teacher.toLowerCase().includes(searchTerm.toLowerCase())
);
  return (
    <section className={style.table}>
      <Table columns={columns} dataSource={filteredData}  />
    </section>
  );
}

