import { FC } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Header } from '../components'
import TestForm from '../pages/TestForm/TestForm'
import LoginStep2 from '../pages/Login/LoginStep2/LoginStep2'
import TeacherLogin from '../pages/TeacherLogin/TeacherLogin'
import StudentProfile from '../pages/StudentProfile/StudentProfile'
import { message } from 'antd'
import LoginStudent from '../pages/LoginStudent/LoginStudent'
import { StudentsTests } from '../pages/StudentsTests/StudentsTests'
import TestAbut from '../pages/TestAbut/TestAbut'
import { TestArchiveAbut } from '../pages/TestArchiveAbut/TestArchiveAbut'
import {
  FinishTest,
  Login,
  RegisterTeacher,
  TeacherPersonal,
  TeacherProfil,
  TestResult,
  TestArchive,
  TestSpart,
  CreateTest,
} from "../pages";
import PrintPage from '../pages/PrintPage/PrintPage'

const Routs: FC = () => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' element={<LoginStep2 />} /> */}
        <Route path='/:token' element={<LoginStep2 />} />
        <Route path='/' element={<Login messageApi={messageApi} />} />
        <Route path='/registerTeacher' element={<RegisterTeacher />} />
        <Route path='/finishTest' element={<FinishTest />} />
        <Route path='/studentLogin' element={<LoginStudent />} />
        <Route path='/testResult' element={<TestResult />} />
        <Route path='/testArchive' element={<TestArchive />} />
        <Route path='/createTest' element={<CreateTest />} />
        <Route path='/testSpart' element={<TestSpart />} />
        <Route path='/teacherLogin' element={<TeacherLogin messageApi={messageApi} />} />
        <Route path="/test/:id" element={<TestForm />} />
        <Route path="/login" element={<Login messageApi={messageApi} />} />
        {/* <Route path="/loginStep2/:token" element={<LoginStep2 />} /> */}
        {/* <Route path="/loginStep2" element={<LoginStep2 />} /> */}
        <Route path="/teacher/teacherProfil" element={<TeacherProfil />} />
        <Route path="/student/studentProfile" element={<StudentProfile />} />
        <Route path="/student/tests/" element={<StudentsTests />}/>
        <Route path="/teacher/testAbut" element={<TestAbut />}/>
        <Route path="/student/abutArchiv" element={<TestArchiveAbut />}/>
        <Route path="/print_page" element={<PrintPage />}>

          {/* <Route path="/teacherPersonal" element={<TeacherPersonal />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routs;
