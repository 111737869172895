const Clock = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_689_1034)">
        <path
          d="M12.5 25C5.60729 25 0 19.3927 0 12.5C0 5.60729 5.60729 0 12.5 0C19.3927 0 25 5.60729 25 12.5C25 19.3927 19.3927 25 12.5 25ZM12.5 2.08333C6.75625 2.08333 2.08333 6.75625 2.08333 12.5C2.08333 18.2437 6.75625 22.9167 12.5 22.9167C18.2437 22.9167 22.9167 18.2437 22.9167 12.5C22.9167 6.75625 18.2437 2.08333 12.5 2.08333ZM17.7083 12.5C17.7083 11.924 17.2427 11.4583 16.6667 11.4583H13.5417V6.25C13.5417 5.67396 13.075 5.20833 12.5 5.20833C11.925 5.20833 11.4583 5.67396 11.4583 6.25V12.5C11.4583 13.076 11.925 13.5417 12.5 13.5417H16.6667C17.2427 13.5417 17.7083 13.076 17.7083 12.5Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_689_1034">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Clock;
