import { UploadOutlined } from '@ant-design/icons';
import style from './InputFile.module.scss';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
interface InputFileProps {
    accept: string,
    onChange: any,
    index:any
}

export const InputFile: FC<InputFileProps> = ({ accept, onChange ,index }) => {
    const currentTest = useSelector((state: any) => state.createTest.currentTest)
    const currentTestId = useSelector((state: any) => state.createTest.currentTestId)
    const [fileURL, setFileURL] = useState<string | null>(null);

    const onHandlerChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            onChange(file)

            setFileURL(URL.createObjectURL(file));
        }
    };
    useEffect(() => {
        const filesTest = currentTest.questions[currentTestId - 1].files
        console.log(filesTest, 'fileTest');

        if (filesTest.length && filesTest[0].type==='photo') {
            setFileURL(filesTest[index].file);
        }
    }, [])
    useEffect(() => {
        return () => {
            if (fileURL) {
                URL.revokeObjectURL(fileURL);
            }
        };
    }, [fileURL]);

    console.log("fileURL",fileURL);
    

    return (
        <div className={style.input_file}>
            <label className={style.input_label}>
                <input type="file" className={style.input_element} accept={accept} onChange={onHandlerChange} />
                <span className={style.input_span}> {fileURL ? '' : (<UploadOutlined style={{ fontSize: '60px', color: '#248B00', marginBottom: '32px' }} />)}
                    {fileURL ? '' : (<p>Перетяните сюда изображение</p>)}
                </span>
            </label>
            {fileURL && <img src={fileURL} alt='photoUrl' className={style.preview_image} />}
        </div>
    );
}
