import React from "react";
import classes from "./Header.module.scss";
import logoHeader from "./icons/logoHeader.svg";
import profile from "./icons/profile.svg";
import test from "./icons/test.svg";
import quation from "./icons/quatition.svg";
import results from './icons/results.svg'
import {Link} from "react-router-dom";
const Header: React.FC = () => {
  return (
    <div className={classes.header}>
      <div className={classes.topHeader}>
        <img src={logoHeader} alt="logo" />
      <Link to="/teacher/teacherProfil" style={{textDecoration:"none"}}> <img src={profile} alt="profile" /></Link> 
      </div>
      <div className={classes.bottomHeader}>
        <Link to="/testSpart"style={{textDecoration:"none"}}>  <button className={classes.btn}>
          <img src={test} alt="test" />
          <span>Все тесты</span>
        </button></Link>
        <Link to="/createTest"style={{textDecoration:"none"}}> <button className={classes.btn}>
          <img src={quation} alt="quation" />
          <span>Вопросы</span>
        </button></Link>
        <Link to="/testArchive" style={{textDecoration:'none',color:'black'}}>  <button className={classes.btn}>
          <img src={results} alt="results" />
          <span>Результаты</span>
        </button></Link>   
        <Link to="/teacher/testAbut" style={{textDecoration:'none',color:'black'}}>  <button className={classes.btn}>
          
          <span>Результаты абитуриента</span>
        </button></Link>   
      </div>
    </div>
  );
};

export default Header;