import { FC } from 'react'
import { ResultHeader, ResultMark } from '../../components/TestResult';
import style from './TestResult.module.scss';
import { useSelector } from 'react-redux';
import { CheckOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
const TestResult: FC = () => {
    const testValue = useSelector((state: any) => state.test.testResult)
    const navigate = useNavigate()
    console.log(testValue);

    return (
        <div className={style.container}>
            <div className={style.header}>
                <h2><span><LeftOutlined /></span> Результаты теста</h2>
                <ul>
                    <li><CheckOutlined />  Обязательный тест</li>
                    <li><CheckOutlined />  Сдать до: 05.06.2024</li>
                   <Link to={'/testArchive'}><li><b><u>Тесты за четверть</u></b></li></Link> 
                </ul>
            </div>
            <ResultMark progress={(testValue.score)} result_answers={testValue} />
            <button className={style.primaryButton} onClick={() => {
                navigate('/student/studentProfile')
            }}>Завершить тест</button>
        </div>
    );
}

export default TestResult;


