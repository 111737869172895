import { FC, useState } from "react";
import { TestChoice } from "../TestChoice/TestChoice";
import classes from './TestComponent.module.scss';
import { TestTextField } from "./TestForForm";
import { TestImg } from "./TestForForm/TestImg/TestImg";
import { TestVideo } from "./TestForForm/TestVideo/TestVideo";
import { ChoiceSquered } from "./TestForForm/ChoiceSquered/ChoiceSquered";

interface Answer {
    id: number,
    answer: string,
    is_active: boolean,
}

interface TestComponentProps {
    test: {
        id: number,
        questions: string,
        type: string,
        answers: Answer[],
    },
    testAllData:any,
    setAnswers:any,
    answers:any
}

export const TestComponent: FC<TestComponentProps> = ({test,testAllData}) => {
    console.log('all',testAllData);
    

    const [result, setResult] = useState([])
    console.log(test.type);
    console.log('owner res',result);
    
    
    return (
        <div>
            <div className={classes.questions}>
                {
                    test.type === 'radio' ? 
                        (<TestChoice questionArr={test.answers} id={test.id} question={test.questions} result={result} setResult={setResult} test={test}/>) 
                        : test.type === 'text' ? (<TestTextField id={test.id} test={test} questions={test.questions} setResult={setResult} result={result}/>) 
                        : test.type === 'Photo' ? (<TestImg test={test} questionArr={test.answers} id={test.id} question={test.questions} setResult={setResult} result={result} />) 
                        : test.type === 'video' ? (<TestVideo test={test} questionArr={test.answers} id={test.id} question={test.questions} setResult={setResult} result={result} />) 
                        : test.type === 'checkbox' ? (<ChoiceSquered test={test} questionArr={test.answers} Qid={test.id} question={test.questions} result={result} setResult={setResult} />) 
                        : ''
                }    
                </div>
                
        </div>
    );
}

