import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginAsync } from '../reducer/authReducer';
import { RootState } from '../store';

interface TestState {
    testResult: any,
    student: {
        full_name: string,
        email: string,
        student_phone: string
    },
    test: any
}

const initialState: TestState = {
    testResult: {},
    student: {
        full_name: 'Aktan',
        email: 'aktan@gmail.com',
        student_phone: '03834834834'
    },
    test: {
        title: '',
        choise: '',
        teacher: '',
        questions: [],
    }
};

const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        addResultTest(state, action) {
            console.log(action);
            console.log(JSON.stringify(state));
            let is_create = false;

            if (state.test.questions.length === 0) {
                state.test.questions.push(action.payload);
                is_create = true;
            }

            state.test.questions.forEach((item: any, idx: any) => {
                if (item.answers && item.answers.length > 0) {
                    if (action.payload.answers[0].type === 'text') {
                        if (action.payload.answers[0].answer_text.slice(0, -1) === item.answers[0].answer_text) {
                            state.test.questions[idx].answers[0].answer_text = action.payload.answers[0].answer_text;
                            is_create = true;
                        }
                    }
                }
            });

            if (!is_create) {
                state.test.questions.push(action.payload);
            }
        }
        ,
        initTest(state, action) {
            state.student = action.payload.student
            state.test = action.payload.test
        },
        setTestResult(state, action){
            state.testResult = action.payload
        },
        addQuestionWithAnswers(state, action: PayloadAction<{ questionId: any, answers: any[] }>) {
            const { questionId, answers } = action.payload;

            state.test.questions.forEach((item:any,index:any)=>{
                if(item.id===questionId){
                    state.test.questions[index].answers = answers
                }
            })
        }
    },
})

export const { addResultTest, initTest ,setTestResult,addQuestionWithAnswers} = testSlice.actions

export default testSlice.reducer;