import { FC, useState } from "react";
import { ChoiceCard } from "./ChoiceCard/ChoiceCard";
import classes from './ChoiceSquered.module.scss';
import TestImageCardBig from "../../../../TestImageCardBig/TestImageCardBig";
import TestImageExmCard from "../../../../TestImageExmCard/TestImageExmCard";
import TestVideoExmCard from "../../../../TestVideoExmCard/TestVideoExmCard";
import TestVideoCardBig from "../../../../TestVideoCardBig/TestVideoCardBig";
import { useDispatch ,useSelector} from "react-redux";
import { addResultTest,addQuestionWithAnswers } from "../../../../../store/slices/TestSlice";
interface ChoiceSqueredProps {
  questionArr: any[];
  question: string | null;
  result: any;
  setResult: any;
  Qid?: number;
  test:any
}

export const ChoiceSquered: FC<ChoiceSqueredProps> = ({ questionArr, question, result, setResult, Qid ,test}) => {

  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const [selected, SetSelected] = useState<number[]>([]);
  const dispatch = useDispatch()  
  const questions = useSelector((state:any)=>state.test.test.questions)
  console.log(questions,'----------------------');
  
  const handleSelectCard = (id: number) => {
    // Qid это id вопроса
    console.log(questionArr, 'qq');
    console.log(selectedCards, 'ss');
    let newAnswers: any = [];
  
    setSelectedCards((prevSelected): any => {
      if (prevSelected.includes(id)) {
        // Если этот ответ уже выбран, убираем его из выбранных
        return prevSelected.filter(cardId => cardId !== id);
      } else {
        // Если не выбран, и при этом еще можно выбирать (кроме одного)
        if (prevSelected.length < questionArr.length - 1) {
          return [...prevSelected, id];
        } else {
          return prevSelected; // Не позволяем выбрать больше, чем возможно
        }
      }
    });
    // const currentQuestIdx = questions.findIndex((item:any)=>item.id===Qid)
    // const currentQuest = questions[currentQuestIdx]
    // console.log(currentQuest,'currentQuestcurrentQuestcurrentQuestcurrentQuest');
    
    questionArr.forEach((item, index) => {
      console.log(selectedCards,'selectedCards');
      console.log(questionArr,'questionArr CHECHECHECHECH');
      // if(currentQuest.length===0){
      //   newAnswers.push({
      //     answer_text: questionArr[index].answer_text,
      //     is_active: true,
      //     type: "checkbox",
      //     file: null
      //   });
      // }

      // if (currentQuest.includes(item.id)) {
      //   newAnswers.push({
      //     answer_text: questionArr[index].answer_text,
      //     is_active: true,
      //     type: "checkbox",
      //     file: null
      //   });
      // } else {
      console.log('iteitemtiteitietei',item);

      if(item.id === id){
        console.log(item.id === id,'item.id === iditem.id === iditem.id === iditem.id === id');
        
        newAnswers.push({
          id:item.id,
          answer_text: questionArr[index].answer_text,
          is_active: true,
          type: "checkbox",
          file: null
        });
        SetSelected([...selected,id])
      }
      selected.forEach((idSelected)=>{
        console.log(idSelected,'idSelected');
        console.log(item.id,'ITEMID');
        
        if(idSelected!==id){
          if(idSelected===item.id){
            newAnswers.push({
              id:item.id,
              answer_text: questionArr[index].answer_text,
              is_active: true,
              type: "checkbox",
              file: null
            });
          }
        }
      })
      console.log(newAnswers,'newAnswersnewAnswersnewAnswersnewAnswers');
      
      // }
    });
    console.log('!!!!');
    console.log(questions);
    if(questions.length===0){
      dispatch(addResultTest({
        id:Qid,
        answers: newAnswers,
        type: "checkbox"
      }));
    }
    questions.forEach((element:any) => {
      console.log('????????');
      
      if(element.id===Qid){
        // dispatch(addResultTest({
        //   id:Qid,
        //   answers: newAnswers,
        //   type: "checkbox"
        // }));

        dispatch(        addQuestionWithAnswers({
          questionId:Qid,
          answers:newAnswers
        }))
      }else{
        dispatch(addResultTest({
          id:Qid,
          answers: newAnswers,
          type: "checkbox"
        }));
      }
    });
  
  };
  
  // const handleSelectCard = (id: number) => {
  //   console.log(questionArr,'qq');
  //   console.log(selectedCards,'ss');
  //   let newAnswers: any = []
    
    
  //   if (questionArr.length > selectedCards.length+1) {
  //     setSelectedCards((prevSelected): any => {

  //       if (prevSelected.includes(id)) {
  //         return prevSelected.filter(cardId => cardId !== id)
  //       }
  //       else {
  //         return [...prevSelected, id]
  //       }


  //     });
  //   }

  //   // questionArr.forEach((item: any) => {

  //     // if (item.id === id) {
  //     //   let is = false
  //     //   console.log(result);
        
  //       // result.forEach((item: any) => {
  //       //   if (selectedCards === item.answer) {
  //       //     is = true
  //       //   }
  //       // })
  //       // if (!is) {
  //       //   console.log(result);
  //       //   const newarr = [...result, {
  //       //     answer: item.id,
  //       //     question: id
  //       //   }]

  //       // }
        
  //     // }
  //   // })
  //   questionArr.forEach((item, index) => {
  //     console.log(item);
  //     if (item.id === id) {
  //       newAnswers.push({
  //         answer_text: questionArr[index].answer_text,
  //         is_active: true,
  //         type:"checkbox",
  //         file:null 
  //       })
  //     } else {
  //       newAnswers.push({
  //         answer_text: questionArr[index].answer_text,
  //         is_active: false,
  //         type:"checkbox",
  //         file:null 
  //       })
  //     }

  //   })
  //   dispatch(addResultTest({
  //     id,
  //     answers: newAnswers,
  //     type:"checkbox"
  //   }))
  // };
  return (
    <section className={classes.section}>
 {
        test.files.length !== 0 ?
          test.files.length === 1 ?
            <div className={classes.quest_wrap}>
              <h2>{question}</h2>
              {
                test.files[0].type ==='photo'?
                <TestImageCardBig url={test.files[0].file} />
                :<TestVideoCardBig url={test.files[0].file}/>
              }
            </div>

            : 
            <div className={classes.quest_wrap}>
              <h2>{question}</h2>
              {
                test.files[0].type ==='photo'?
                <TestImageExmCard files={test.files} />
                :<TestVideoExmCard files={test.files}/>
              }
            </div>

             : <h2>{question}</h2>
      }
      <div className={classes.cardContainer}>
        {questionArr.map(({ id, answer_text }) => (
          <ChoiceCard
            key={id}
            id={id}
            text={answer_text}
            onSelect={handleSelectCard}
            isSelected={selectedCards.includes(id)}
          />
        ))}
      </div>
    </section>
  );
}

