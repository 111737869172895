import React, { useState } from 'react';
import classes from './LoginStep2.module.scss';
import { FormInput } from '../../../components/UI';
import ownerLogo from '../../../assets/icons/OwnerLogo.svg';
import Warning from '../../../assets/icons/Warning.svg';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import answers from '../../../store/slices/answers';
import { UploadOutlined } from '@ant-design/icons';

const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

const LoginStep2 = () => {
    const [error, setError] = useState(false);
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        full_name: '',
        student_phone: '',
        username: '',
        password: '',
        completed_class: '',
        gender: 'М',
        parent_full_name: '',
        region: '',
        guardian_full_name: '',
        parent_contact_number: '',
        whatsapp_number: '',
        guardian_contact_number: '',
        achievements: '',
        interested_subjects: '',
        confirm_password: '',
        exam_language: 'Русский язык',
        talents: '',
        photo: null
    });
    const [touched, setTouched] = useState({
        full_name: false,
        student_phone: false,
        username: false,
        password: false,
        completed_class: false,
        gender: false,
        parent_full_name: false,
        region: false,
        guardian_full_name: false,
        parent_contact_number: false,
        whatsapp_number: false,
        guardian_contact_number: false,
        achievements: false,
        interested_subjects: false,
        confirm_password: false,
        exam_language: false,
        talents: false,
        photo: false
    });
    const [file, setFile] = useState<any>(null);
    const [date, setDate] = useState<any>(null);
    const {token} = useParams()

    const handleFileChange = (event: any) => {
        console.log(event.target.files[0]);

        setFile(event.target.files[0]);
    };

    // const handleSubmitFile = (event: any) => {
    //     event.preventDefault();
    //     const formData = new FormData();
    //     formData.append('file', file);
    // };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { id } = e.target;
        setTouched({
            ...touched,
            [id]: true
        });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const isValid = Object.values(formData).every(value => value);
        const passwordsMatch = formData.password === formData.confirm_password;
        console.log(isValid);
        console.log('pass', passwordsMatch);
        console.log(file);
        const Multidata =new FormData()
        Multidata.append('full_name',formData.full_name)
        Multidata.append('student_phone',formData.student_phone)
        Multidata.append('birth_date',date)
        Multidata.append('username',formData.username)
        Multidata.append('password',formData.password)
        Multidata.append('completed_class',formData.completed_class)
        Multidata.append('gender',formData.gender)
        Multidata.append('parent_full_name',formData.parent_full_name)
        Multidata.append('region',formData.region)
        Multidata.append('guardian_full_name',formData.guardian_full_name)
        Multidata.append('parent_contact_number',formData.parent_contact_number)
        Multidata.append('guardian_contact_number',formData.guardian_contact_number)
        Multidata.append('achievements',formData.achievements)
        Multidata.append('interested_subjects',formData.interested_subjects)
        Multidata.append('confirm_password',formData.confirm_password)
        Multidata.append('exam_language',formData.exam_language)
        Multidata.append('whatsapp_number',formData.whatsapp_number)
        Multidata.append('talents',formData.talents)
        Multidata.append('photo',file)
        console.log(date, 'date');
        const photoFile = await getBase64(file)
        console.log('s', photoFile);

        // const b={
        //     ...formData,
        //     photo:photoFile,
        //     birth_date:date
        // }
        if (isValid || passwordsMatch) {
            if (token) {
                console.log(formData);
                const b = {
                    ...formData,
                    photo: photoFile,
                    birth_date: date
                }
                console.log(b, 'bbb');
                console.log(token);
                fetch(`https://yiman.webtm.ru/api/v1/users/register/${token}/`, {
                    method: 'PUT',
                    body: Multidata
                }).then((res) => {
                    console.log(res);
                    if(res.ok){    
                    }else{
                        alert('Ошибка в заполнений')
                    }
                    return res.json()
                }).then((data:any) => {
                    console.log(data);
                    localStorage.setItem('access',data.access)
                
                    if(data.access){
                        navigate('/student/studentProfile')
                        // fetch('https://yiman.webtm.ru/api/v1/users/student/tests/', {
                        //     headers: {
                        //       Authorization: `Bearer ${localStorage.getItem('access')}`
                        //     }
                        //   }).then((res) => {
                        //     return res.json()
                        //   }).then((data) => {
                        //     console.log(data);
                        //     navigate('/test/'+data[0].id)
                        //   })
                    }
                })
            } else {
                alert('no token')
            }
            setError(false);

        } else {
            setError(true);

        }
    };

    

    return (
        <div className={classes.Register}>
            <img src={ownerLogo} alt='' className={classes.ownerLogo} />
            <h1>Вход</h1>
            <form className={classes.formInput} onSubmit={handleSubmit}>
                {error && (
                    <div className={classes.error}>
                        <img src={Warning} alt='Warning' />
                        <p>Не верная эл. почта или пароль</p>
                    </div>
                )}
                <div className={classes.flexInput}>
                    <FormInput
                        type='text'
                        labelText={formData.username.length > 0 ? '' : 'username'}
                        id='username'
                        value={formData.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={error && !formData.username ? 'This field is required' : ''}
                        touched={touched.username}
                    />
                    <FormInput
                        type='text'
                        labelText={formData.full_name.length > 0 ? '' : 'Фио'}
                        id='full_name'
                        value={formData.full_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={error && !formData.full_name ? 'This field is required' : ''}
                        touched={touched.full_name}
                    />
                </div>
                <FormInput
                    type='number'
                    labelText={formData.student_phone.length > 0 ? '' : 'Телефон номер студента'}
                    id='student_phone'
                    value={formData.student_phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.student_phone ? 'This field is required' : ''}
                    touched={touched.student_phone}
                />
                <br />
                {/* <input type="file" onChange={handleFileChange} required/> */}
                <div className={classes.input_file}>
                    <label className={classes.input_label}>
                        <input type="file" className={classes.input_element} onChange={handleFileChange} />
                        <span className={classes.input_span}> (<UploadOutlined style={{ fontSize: '60px', color: '#248B00', marginBottom: '32px' }} />)
                        </span>
                    </label>
                </div>

                {/* <FormInput
                    type='text'
                    labelText='Дата рождение'
                    id='birth_date'
                    value={formData.birth_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.birth_date ? 'This field is required' : ''}
                    touched={touched.birth_date}
                /> */}
                <input type="date" className={classes.styled_date_input} onChange={(e) => {
                    console.log(e.target.value);

                    setDate(e.target.value)
                }} />
                {/* <FormInput
                    type='text'
                    labelText='gender'
                    id='gender'
                    value={formData.gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.gender ? 'This field is required' : ''}
                    touched={touched.gender}
                /> */}
                <br />
                <h1>Ваш пол</h1>
                <select name="" className={classes.styled_select} id=""required onChange={(e)=>{
                    setFormData({
                        ...formData,
                        gender:e.target.value
                    })
                }}>
                    <option value="М">Мужский</option>
                    <option value="Ж">Женский</option>
                </select>
                <FormInput
                    type='text'
                    labelText={formData.region.length > 0 ? '' : 'Регион'}
                    id='region'
                    value={formData.region}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.region ? 'This field is required' : ''}
                    touched={touched.region}
                />
                <FormInput
                    type='text'
                    labelText={formData.parent_full_name.length > 0 ? '' : 'Полное ФИО родителей'}
                    id='parent_full_name'
                    value={formData.parent_full_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.parent_full_name ? 'This field is required' : ''}
                    touched={touched.parent_full_name}
                />
                <FormInput
                    type='number'
                    labelText={formData.parent_contact_number.length > 0 ? '' : 'Контактный номер родителя'}
                    id='parent_contact_number'
                    value={formData.parent_contact_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.parent_contact_number ? 'This field is required' : ''}
                    touched={touched.parent_contact_number}
                />
                <FormInput
                    type='text'
                    labelText={formData.guardian_full_name.length > 0 ? '' : 'ФИО опекуна'}
                    id='guardian_full_name'
                    value={formData.guardian_full_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.guardian_full_name ? 'This field is required' : ''}
                    touched={touched.guardian_full_name}
                />
                <FormInput
                    type='text'
                    labelText={formData.guardian_contact_number.length > 0 ? '' : 'Контактный номер опекуна'}
                    id='guardian_contact_number'
                    value={formData.guardian_contact_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.guardian_contact_number ? 'This field is required' : ''}
                    touched={touched.guardian_contact_number}
                />
                <FormInput
                    type='text'
                    labelText={formData.whatsapp_number.length > 0 ? '' : 'Whatsapp номер'}
                    id='whatsapp_number'
                    value={formData.whatsapp_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.whatsapp_number ? 'This field is required' : ''}
                    touched={touched.whatsapp_number}
                />
                <FormInput
                    type='text'
                    labelText={formData.interested_subjects.length > 0 ? '' : 'Интересующие предметы'}
                    id='interested_subjects'
                    value={formData.interested_subjects}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.interested_subjects ? 'This field is required' : ''}
                    touched={touched.interested_subjects}
                />
                <FormInput
                    type='text'
                    labelText={formData.achievements.length > 0 ? '' : 'Достижения'}
                    id='achievements'
                    value={formData.achievements}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.achievements ? 'This field is required' : ''}
                    touched={touched.achievements}
                />
                <FormInput
                    type='text'
                    labelText={formData.talents.length > 0 ? '' : 'Таланты'}
                    id='talents'
                    value={formData.talents}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.talents ? 'This field is required' : ''}
                    touched={touched.talents}
                />
                {/* <FormInput
                    type='text'
                    labelText='exam_language'
                    id='exam_language'
                    value={formData.exam_language}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.exam_language ? 'This field is required' : ''}
                    touched={touched.exam_language}
                /> */}
                       <h1>Язык</h1>
                <select name="" id="" className={classes.styled_select} required onChange={(e)=>{
                    setFormData({
                        ...formData,
                        exam_language:e.target.value
                    })
                }}>
                    <option value="Русский язык">Русский язык</option>
                    <option value="Кыргыз тили">Кыргыз тили</option>
                </select>
                <FormInput
                    type='password'
                    labelText={formData.password.length > 0 ? '' : 'Пароль'}
                    id='password'
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.password ? 'This field is required' : ''}
                    touched={touched.password}
                />
                <FormInput
                    type='password'
                    labelText={formData.confirm_password.length > 0 ? '' : 'Подверждение пароля'}
                    id='confirm_password'
                    value={formData.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error ? (formData.confirm_password !== formData.password ? 'Passwords do not match' : 'This field is required') : ''}
                    touched={touched.confirm_password}
                />
                <FormInput
                    type='number'
                    labelText={formData.completed_class.length > 0 ? '' : 'Оконченный класс'}
                    id='completed_class'
                    value={formData.completed_class}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error && !formData.completed_class ? 'This field is required' : ''}
                    touched={touched.completed_class}
                />
                <button className={classes.btn} type='submit'>
                    Продолжить
                </button>
            </form>
        </div>
    );
};

export default LoginStep2;

