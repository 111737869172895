import { FC, useState } from "react";
import classes from './TestVideo.module.scss';
import { VideoCard } from "./VideoCard/VideoCard";
import TestImageCardBig from "../../../../TestImageCardBig/TestImageCardBig";
import TestImageExmCard from "../../../../TestImageExmCard/TestImageExmCard";
import TestVideoExmCard from "../../../../TestVideoExmCard/TestVideoExmCard";
import TestVideoCardBig from "../../../../TestVideoCardBig/TestVideoCardBig";
interface TestVideoProps {
    questionArr: any[];
    question: string | null;
    result: any;
    setResult: any;
    id?: number;
    test:any
}

export const TestVideo: FC<TestVideoProps> = ({questionArr, question, result, setResult, id , test}) => {
    
    const [selectedCard, setSelectedCard] = useState<number | null>(null);

    const handleSelectCard = (idCard: number) => {
    setSelectedCard(idCard);

    questionArr.forEach((item: any) => {

      if (item.id === idCard) {
        let is = false
        result.forEach((item: any) => {
          if (selectedCard === item.answer) {
            is = true
          }
        })
        if (!is) {
          console.log(result);

          const newarr = [...result, {
            answer: item.id,
            question: id
          }]
          setResult(newarr)
        }

      }
    })
  };

    return (
        <section className={classes.section}>
         
{
        test.files.length !== 0 ?
          test.files.length === 1 ?
            <div className={classes.quest_wrap}>
              <h2>{question}</h2>
              {
                test.files[0].type ==='photo'?
                <TestImageCardBig url={test.files[0].file} />
                :<TestVideoCardBig url={test.files[0].file}/>
              }
            </div>

            : 
            <div className={classes.quest_wrap}>
              <h2>{question}</h2>
              {
                test.files[0].type ==='photo'?
                <TestImageExmCard files={test.files} />
                :<TestVideoExmCard files={test.files}/>
              }
            </div>

             : <h2>{question}</h2>
      }

            <div className={classes.cardContainer}>
                {questionArr.map(({ id, answer }) => (
                <VideoCard
                    type={'test'}
                    key={id}
                    id={id}
                    text={answer}
                    onSelect={handleSelectCard}
                    isSelected={selectedCard === id}
                />
                ))}
            </div>

        </section>
    );
}

