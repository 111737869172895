import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slices/authSlice'
import TestSlice from './slices/TestSlice'
import createTest from './slices/createTest'
import profileSlice from "./slices/profileSlice"
export const store = configureStore({
  reducer: {
    auth: authSlice,
    test:TestSlice,
    createTest,
    profile:profileSlice
  }})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch 