import React from 'react'
import classes from './TextFiled.module.scss'
import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
interface Props {
  onChange: any,
  value: any
}
export const TextField: React.FC<Props> = ({ onChange, value }) => {
  const currentTest = useSelector((state: any) => state.createTest.currentTest)
  const currentTestId = useSelector((state: any) => state.createTest.currentTestId)
  console.log(value);
  // {currentTest.questions[currentTestId-1].answers[0]?currentTest.questions[currentTestId-1].answers[0].answer_text:''}
  const areaRef = useRef<any>(null)
  useEffect(() => {
    console.log('use!');
    console.log(areaRef);
    areaRef.current.value = value
  }, [])
  if(value[0]){
     return (
    <div className={classes.textField} >
      {
        value.length !== -1 ?
          <textarea placeholder='Напишите ответ' ref={areaRef} onChange={onChange}>{currentTest.questions[currentTestId-1].answers[0].answer_text}</textarea>
          : ''
      }
    </div>
  ) 
  }else{
    return (
      <div className={classes.textField} >
        {
          value.length !== -1 ?
            <textarea placeholder='Напишите ответ' ref={areaRef} onChange={onChange}>{''}</textarea>
            : ''
        }
      </div>
    ) 
  }

}
