import { FC ,useState,useEffect } from "react";
import { SearchHeaderStudent } from "../../components/SearchHeaderStudent/SearchHeader";
import {StudentsTestsTable} from "./StudentsTestsTable";
import style from './StudentsTests.module.scss';
import { Navigate } from "react-router-dom";
export const StudentsTests: FC = () => {

    const [searchTerm, setSearchTerm] = useState<string>('');

   
    // const [data,setData] = useState([])

    // useEffect(()=>{
    //     fetch('https://yiman.webtm.ru/api/v1/users/student/tests/',{
    //         headers:{
    //             Authorization: `Bearer ${localStorage.getItem('access')}`
    //         }
    //     }).then(async(res)=>{
    //         const data = await res.json()
    //         console.log(data);
    //         setData(data)
    //     })
    // },[]) 
    if(!localStorage.getItem('access')){
        return <Navigate  to={'/login'}/>
    }   
    return (
        <div className={style.cont}>
            <SearchHeaderStudent searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <StudentsTestsTable searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
        </div>
    );
}
