import React, { useEffect, useState } from "react";
import classes from "./StudentProfile.module.scss";
import write from "./icons/write.svg";
import { Header } from "../../components";
import {
  setClass,
  setEmail,
  setNameThree,
  setName,
  setLastName,
  setProfile
} from "../../store/slices/profileSlice";
import { Navigate } from "react-router-dom";
import cloaseIcon from './cross (1) 1.svg'
import { useSelector, useDispatch } from "react-redux";
import { date } from "yup";
import { Link } from "react-router-dom";
import { setTestLocation } from "../../store/slices/authSlice";
import StudentHeader from "../../components/studentHeader/studentHeader";
const StudentProfile: React.FC = () => {
  const [profile, setProfile] = useState<any>({})
  const [tests,setTests] = useState<any>([])
  const token = localStorage.getItem('access')
  const [isModal, setisModal] = useState(false)
  const [teacherData, setTeacherData] = useState('Имя')
  const [sendData, setSendData] = useState('')
  const [studentId, setStudentId] = useState(null)
  const dispatch = useDispatch()
  const {loaction_test} = useSelector((state:any)=>state.auth)
console.log(loaction_test,'loaction_testloaction_testloaction_testloaction_testloaction_test');
  const [isValid,setValid] = useState(false)
  // const [isModal,set]
  useEffect(() => {
    fetch('https://yiman.webtm.ru/api/v1/tests/switch/').then(async(res)=>{
      const data = await res.json()
      console.log(data);
      setValid(data[0].is_active)
      
    })

    fetch('https://yiman.webtm.ru/api/v1/users/student/tests/', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`
      }
    }).then((res) => {
      return res.json()
    }).then((data) => {
      console.log(data,'tests');
      if(loaction_test==0){
        dispatch(setTestLocation(data[0].id))
        if(!localStorage.getItem('current_enrollee_test_id')){
          localStorage.setItem('current_enrollee_test_id',JSON.stringify(data[0].id))
          localStorage.setItem('current_enrollee_data',JSON.stringify(data))
        }

        setTests(data)
      }
    })
  }, [])
  useEffect(() => {
    fetch('https://yiman.webtm.ru/api/v1/users/student/detail/', {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((res) => {
      return res.json()
    }).then((data: any) => {
      console.log(data);
      setStudentId(data.id)
      setProfile(data)
    })
  }, [])

  const onEdit = async (e: any) => {
    const value = e.target.parentElement.parentElement.parentElement.getAttribute('data-name')
    console.log('value', value);
    setTeacherData(value)


    setisModal(true)

  }
  console.log(localStorage.getItem('current_enrollee_test_id')!==undefined,"localStorage.getItem('current_enrollee_test_id')!==undefined");
  if(!isValid){
    return <h1>Доступ к тестам закрыт</h1>
  }
  if (!localStorage.getItem('access')) {
    return <Navigate to={'/login'} />
  }
  return (
    <div className={classes.body}>
      <StudentHeader/>
      {
        profile ?
          <>
            {
              isModal ?
                <div className={classes.modal}>
                  <div className={classes.modal_top}>
                    <p>Ваше {teacherData}</p>
                    <img src={cloaseIcon} alt="" onClick={() => setisModal(false)} />
                  </div>
                  <form onSubmit={async (e) => {
                    e.preventDefault()
                    if (teacherData === 'Имя') {
                      const res = await fetch(`https://yiman.webtm.ru/api/v1/users/student/update/${studentId}/`, { method: 'PUT', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ ...profile, first_name: sendData }) })
                      setisModal(false)
                      console.log(res);

                    } else if (teacherData === 'Фамилия') {
                      const res = await fetch(`https://yiman.webtm.ru/api/v1/users/student/update/${studentId}/`, { method: 'PUT', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ ...profile, middle_name: sendData }) })
                      console.log(res);
                      setisModal(false)
                    } else if (teacherData === 'Отчетво') {
                      const res = await fetch(`https://yiman.webtm.ru/api/v1/users/student/update/${studentId}/`, { method: 'PUT', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ ...profile, last_name: sendData }) })
                      console.log(res);
                      setisModal(false)
                    } else if (teacherData === 'Класс') {
                      console.log('send', sendData);

                      const res = await fetch(`https://yiman.webtm.ru/api/v1/users/student/update/${studentId}/`, { method: 'PUT', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ ...profile, completed_class: sendData }) })
                      console.log(res);
                      setisModal(false)

                    } else if (teacherData === 'Электронная почта') {
                      const res = await fetch(`https://yiman.webtm.ru/api/v1/users/student/update/${studentId}/`, { method: 'PUT', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ ...profile, email: sendData }) })
                      console.log(res);
                      setisModal(false)
                    }
                  }}>
                    <input type="text" onInput={(e: any) => setSendData(e.target.value)} />
                  </form>

                </div>
                : ''
            }
            {/* <Header /> */}
            <div className={classes.teacherPersonal}>
              <h1>Персональные данные ученика</h1>

              <div className={classes.dataPersonal}>
                <div className={classes.info} data-name="Имя">
                  <p className={classes.green}>Имя</p>
                  <div>
                    <p>{profile.first_name}</p>
                    <button>
                      <img src={write} alt="change" onClick={onEdit} />
                    </button>
                  </div>
                </div>
                <div className={classes.info} data-name="Фамилия">
                  <p className={classes.green} >Фамилия</p>
                  <div>
                    <p>{profile.middle_name}</p>
                    <button>
                      <img src={write} alt="change" onClick={onEdit} />
                    </button>
                  </div>
                </div>
                <div className={classes.info} data-name="Отчетво">
                  <p className={classes.green}>Отчетво</p>
                  <div>
                    <p>{profile.last_name}</p>
                    <button>
                      <img src={write} alt="change" onClick={onEdit} />
                    </button>
                  </div>
                </div>
                <div className={classes.info} data-name="Класс">
                  <p className={classes.green}>Класс</p>
                  <div>
                    <p>{profile.completed_class}</p>
                    <button>
                      <img src={write} alt="change" onClick={onEdit} />
                    </button>
                  </div>
                </div>
                <div className={classes.info} data-name="Электронная почта">
                  <p className={classes.green}>Электронная почта</p>
                  <div>
                    <p>{profile.email}</p>
                    <button>
                      <img src={write} alt="change" onClick={onEdit} />
                    </button>
                  </div>
                </div>
              </div>
     
              
            </div>
            <div className={classes.allTestWrap}>
         <Link to="/student/tests">  <button className={classes.allTests}>Все тесты</button></Link> 
         
      {
        (tests[0] || loaction_test)&&localStorage.getItem('current_enrollee_test_id')!==undefined?
         <Link to={"/test/"+(localStorage.getItem('current_enrollee_test_id'))}>  <button className={classes.allTests}>Пройти тесты для абитуриента</button></Link> 
:''
      }
              </div></>
          : ''
      }

    </div>
  );
};

export default StudentProfile;





