import { createAsyncThunk } from "@reduxjs/toolkit";

import { api } from "../../api";

export const loginAsync = createAsyncThunk(
    'auth/login',
    async ({email} : {email: string}) => {
        // const response = await api.login(email);
        // return response.data;
    }
)