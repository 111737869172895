import React from 'react'
import classes from './TestVideoExmCard.module.scss'

interface Props{
    files:any,
}

const TestVideoExmCard:React.FC<Props> = ({files}) => {
  return (
    <div className={classes.cardContainer}>
        {
            files.map((file:any)=>{
                return <div className={classes.card}>
                    <span className={classes.card_number}>{file.id}</span>
                <video src={'https://yiman.webtm.ru'+file.file} controls></video>
                </div>
            })
        }
    </div>
  )
}

export default TestVideoExmCard