import { FC, useState } from "react";
import style from './SearchHeader.module.scss'
import emblema from '../../assets/icons/OwnerLogo.svg'
import { FileDoneOutlined, QuestionCircleOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import iconAllTest from '../../assets/icons/test 1.png';
import {Link} from "react-router-dom";
interface Props{
    setSearchTerm:any,
    searchTerm:any
}
export const SearchHeaderStudent: FC<Props> = ({searchTerm,setSearchTerm}) => {
    const [active, setActive] = useState<number>();

    const handlerButton = (id: number) => {
        setActive(id);
        console.log(id);

    }
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };



    return (
        <div className={style.cont}>
            <div className={style.flexCont}>
                <div className={style.img}>
                    <img src={emblema} alt="" />
                </div>
                <div className={style.search}>
                    <SearchOutlined style={{ fontSize: '29px' }} />
                    <input type="text" placeholder='Поиск' onInput={handleSearch} />
                </div> <Link to={'/student/studentProfile'}>
                <div className={style.userIcon}>
                <UserOutlined style={{ fontSize: '27px', color: '#248B00' }} />
                </div>  </Link> 
            </div>

            <div className={style.category}>
                <div className={style.flexCont}>
                    <div className={style.allTest}>
                  <Link to="/student/tests/"style={{textDecoration:'none'}}>  <button className={active === 1 ? style.activeButton : ''} onClick={() => handlerButton(1)}><img src={iconAllTest} alt="" /> Все тесты</button></Link>
                    </div>
                    <div>
                     <Link to="/testArchive" style={{textDecoration:'none',color:'black'}}><button className={active === 3 ? style.activeButton : ''} ><FileDoneOutlined style={{ fontSize: '28px', paddingRight: '4.5px' }} /> Результаты</button></Link>   
                    </div>
                    <div>
                    <Link to="/student/abutArchiv" style={{textDecoration:'none',color:'black'}}>  <button className={style.btn}>
          
          <span>Результаты абитуриента</span>
        </button></Link>   
                    </div>
                </div>

            </div>
        </div>
    );
}

