
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { log } from 'console';
import { number, string } from 'yup';
interface CreateTestState {
    currentTest: any;
    currentTestId: any;
}

const initialState: CreateTestState = {
    currentTest: null,
    currentTestId: 0
};

const createTestSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        changeCurrentTest(state, action) {
            // console.log(action);
            state.currentTest = action.payload
        },
        createTest(state, action) {
            state.currentTest = action.payload
        },
        createQuestion(state, action) {
            const newState = JSON.parse(JSON.stringify(state))

            newState.currentTest.questions = [...newState.currentTest.questions, action.payload]
            // console.log('new', newState);
            state.currentTest = newState.currentTest
            state.currentTestId = action.payload.id
            console.log('ascascascascasfwrab', newState.currentTest);

        },
        deleteQuestion(state, action: PayloadAction<{ id: number }>) {
            const newState = JSON.parse(JSON.stringify(state.currentTest))
            if(action.payload.id>0){
                const filtered = newState.questions.filter((item:any) => item.id !== action.payload.id);
                console.log(filtered);
                newState.questions = filtered
                state.currentTest = newState
            }

            // if (state.currentTest) {
            //     const newState = JSON.parse(JSON.stringify(state));
            //     newState.currentTest.questions = newState.currentTest.questions.filter((question : any) => question.id !== action.payload.id)
            //     state.currentTest = newState.currentTest;
            //     const remainingIds = Object.keys(newState.currentTest.questions);
            //     state.currentTestId = remainingIds.length > 0 ? parseInt(remainingIds[0]) : null;
            // }
        },
        setCurrentTestId(state, action) {
            state.currentTestId = action.payload
        },
        setQuestionTitle(state, action) {
            state.currentTest.questions[action.payload.idx].questions = action.payload.title
        },
        setTypeQuestion(state, action) {
            state.currentTest.questions[action.payload.idx].type = action.payload.type
        },
        createAnswer(state, action) {
            const { idx, answer } = action.payload
            const newState = JSON.parse(JSON.stringify(state))
            // console.log(action);

            if (answer.type == 'text') {
                // console.log('asssaasas');

                newState.currentTest.questions.forEach((item: any) => {
                    // console.log('quest', item);
                    // console.log(idx, 'idx');

                    if (item.id === idx + 1) {
                        if (state.currentTest.questions[idx].answers.length) {
                            // console.log(JSON.stringify(state.currentTest.questions[idx].answers));

                            state.currentTest.questions[idx].answers[answer.id - 2].answer_text = answer.answer_text
                        } else {
                            newState.currentTest.questions[idx].answers = [...newState.currentTest.questions[idx].answers, answer]
                            // console.log('new', newState);
                            state.currentTest = newState.currentTest
                        }

                    }
                })
            } else {
                newState.currentTest.questions[idx].answers = [...newState.currentTest.questions[idx].answers, answer]
                // console.log('new', newState);
                state.currentTest = newState.currentTest
            }

        },
        deleteAnswer(state, action) {
            const { idx, answerId } = action.payload
            const newState = JSON.parse(JSON.stringify(state))
            newState.currentTest.questions[idx].answers.splice(answerId - 1, 1)
            const newansers = newState.currentTest.questions[idx].answers.map((obj: any, index: number) => {
                obj.id = index + 1
                return obj
            })
            newState.currentTest.questions[idx].answers = newansers
            state.currentTest = newState.currentTest
        },
        selectTrueAnswer(state, action) {
            const { idx, answerId, type } = action.payload
            const newState = JSON.parse(JSON.stringify(state))
            if (type === 'radio') {
                newState.currentTest.questions[idx].answers.forEach((value: any, index: any,) => {
                    if (answerId === value.id) {
                        newState.currentTest.questions[idx].answers[index].is_active = true
                    } else {
                        newState.currentTest.questions[idx].answers[index].is_active = false
                    }
                })

            } else if (type === 'checkbox') {
                // console.log(answerId);
                // console.log(idx);

                newState.currentTest.questions[idx].answers.forEach((value: any, index: any,) => {
                    if (answerId === value.id) {
                        newState.currentTest.questions[idx].answers[index].is_active = value.is_active ? false : true
                    }
                })
            }
            state.currentTest = newState.currentTest
        },
        setTitleQuest(state, action) {
            state.currentTest.title = action.payload
        },
        addFile(state, action) {
            const { idx, files } = action.payload  
            // console.log('addFile',action);
            
            const newState = JSON.parse(JSON.stringify(state.currentTest))

            newState.questions[idx].files = files.files
            // console.log('newState',newState);
            
            state.currentTest = newState
        },
        clearFiles(state,action){
            const newState = JSON.parse(JSON.stringify(state.currentTest))
            if(newState.questions[action.payload.idx]){
                if(action.payload.idx-1>0){
                    newState.questions[action.payload.idx-1].files=[]
                    // console.log('NewState',newState);
                    
                }
            }
            state.currentTest = newState
        },
        setTypeFileQuest(state,action){
            const newState = JSON.parse(JSON.stringify(state.currentTest))
            // newState.questions[action.payload.idx].files 
        },
        setPassingScore(state,action){
            console.log(action,'passingscore');
            
            const newState = JSON.parse(JSON.stringify(state.currentTest))
            // newState.questions[action.payload.idx].files 
            newState.passing_score = +action.payload
            console.log('bewstate',newState);
            
            state.currentTest = newState
        },
        setMaksScore(state,action){
            console.log(action,'maks');
            
            const newState = JSON.parse(JSON.stringify(state.currentTest))
            // newState.questions[action.payload.idx].files 
            newState.max_score = +action.payload
            console.log('bewstate',newState);
            
            state.currentTest = newState
        },
        setClass(state, action) {
            state.currentTest.for_class = action.payload
        },
        setFilesCount(state,action){
            const {idx,count} = action.payload
            const newState = JSON.parse(JSON.stringify(state.currentTest))
            newState.questions[idx].files_count = count
            state.currentTest = newState
        },
        setFilesType(state,action){
            const {idx,type} = action.payload
            const newState = JSON.parse(JSON.stringify(state.currentTest))
            newState.questions[idx].files_type = type
            state.currentTest = newState
        },
    },
})

export const {
    changeCurrentTest,
    createTest,
    createQuestion,
    setCurrentTestId,
    setQuestionTitle,
    setTypeQuestion,
    createAnswer,
    deleteAnswer,
    setTitleQuest,
    selectTrueAnswer,
    addFile,
    clearFiles,
    setFilesCount,
    setFilesType,
    deleteQuestion,
    setPassingScore,
    setMaksScore,
    setClass

} = createTestSlice.actions

export default createTestSlice.reducer;