import { FC, useState } from 'react'
import classes from '../../../pages/TestForm/testform.module.scss';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import { useSelector ,useDispatch} from 'react-redux';
import type { RcFile, UploadProps } from 'antd/es/upload/interface';
import { createAnswer } from '../../../store/slices/createTest';
import TestVideoCard from './TestVideoCard/TestVideoCard';

interface TestVideoProps {
    question: string | null;

}

const getBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const TestVideo: FC<TestVideoProps> = ({question}) => {
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
    const [isAddedQuest, setIsAddedQuest] = useState<boolean>(false);
    const [newQuestion, setNewQuestion] = useState<any>('');
    const currentTest = useSelector((state: any) => state.createTest.currentTest)
    const currentTestId = useSelector((state: any) => state.createTest.currentTestId)
    const dispatch = useDispatch()
    const [imageUrl, setImageUrl] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [blobImg, setBlobImg] = useState<any>(null);
    const [cards, setCards] = useState([
        {
            text: 'newQuestion',
            id: 1,
            imgURL: 'https://i.pinimg.com/originals/03/d7/2d/03d72d4f3a4b9b05dd6ad5306d3956a7.jpg'
        }])
    const handleSelectCard = (id: number) => {
        setSelectedCard(id);
    };
    const handleAddQuestion = () => {
            const idx = currentTestId - 1
            const answerId = currentTest.questions[idx].answers.length > 0 ? currentTest.questions[idx].answers.length + 1 : 1

            const payload = {
                idx,
                answer: {
                    id: answerId,
                    answer: imageUrl,
                    file: blobImg,
                    is_active: false
                }
            }
            dispatch(createAnswer(payload))
            setNewQuestion('');
            setIsAddedQuest(false);
            setIsAddedQuest(false);
        
    };
    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    )
    const handleChange: UploadProps['onChange'] = async (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            const url = await getBase64(info.file.originFileObj as RcFile);
            setLoading(false);
            setImageUrl(url);
            setBlobImg(info.file)
           
        }
    };

    const beforeUpload = async (file: RcFile) => {
        const isMp4OrMkv = file.type === 'video/mp4' || file.type === 'video/x-matroska';
        if (!isMp4OrMkv) {
            message.error('You can only upload MP4/MKV file!');
            return false;
        }
        // const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isLt2M) {
        //     message.error('Image must smaller than 2MB!');
        //     return false;
        // }
        const url = await getBase64(file);
        setBlobImg(file)
        setLoading(false);
        setImageUrl(url);
        return false; 
    };

  return (
    <section className={classes.section}>
            <h2>{question}</h2>
            <div className={classes.cardContainer}>
                {currentTest.questions[currentTestId - 1].answers.map(({ id, answer,is_active }:any) => (
                    <TestVideoCard
                        key={id}
                        id={id}
                        setQuestionArr={setCards}
                        questionArr={cards}
                        imgURL={answer}
                        onSelect={handleSelectCard}
                        isSelected={is_active}
                    />
                ))}
            </div>
            <button onClick={() => setIsAddedQuest(true)} className={classes.btnAdd}>добавить</button>
            {isAddedQuest && (
                <div className={classes.choiceAdded}>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                    <button onClick={() => setIsAddedQuest(false)}>закрыть</button>
                    <button onClick={handleAddQuestion}>добавить</button>
                </div>
            )}
        </section>
  )
};