import { FC } from "react";
import classes from './QuestionTestCard.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { selectTrueAnswer } from "../../../store/slices/createTest";

interface QuestionTestCardProps {
    id: number;
    text: string;
    onSelect: any;
    isSelected: boolean;
    questionArr?: any
    setQuestionArr?:any
    type?:any
  }

export const QuestionTestCard: FC<QuestionTestCardProps> = ({id, text, onSelect, isSelected, questionArr, setQuestionArr,type,...props}) => {
    
    const handleClick = () => {
        onSelect(id);
    };

    const currentTest = useSelector((state:any)=>state.createTest.currentTest)
    const currentTestId = useSelector((state:any)=>state.createTest.currentTestId)    

    return (
        <div className={`${classes.card} ${isSelected ? classes.selected : ''}`}{...props} onClick={handleClick}>
            <p className={`${classes.text} ${isSelected ? classes.selected : ''}`}>{text}</p>
            <div className={`${classes.circle} ${isSelected ? classes.selected : ''}`}>
                <span>{id}</span>
            </div>  
        </div>
    );
}
