import { FC } from "react";
import classes from './ImgCard.module.scss';

interface ImgCardProps {
    id: number;
    text: string;
    onSelect: any;
    isSelected: boolean;
    questionArr?: any
    setQuestionArr?:any
    type?:any
  }

export const ImgCard: FC<ImgCardProps> = ({id, text, onSelect, isSelected, questionArr, setQuestionArr,type,...props}) => {
    const handleClick = () => {
        onSelect(id);
    };
    return (
       <div className={`${classes.card} ${isSelected ? classes.selected : ''}`} onClick={handleClick}>
            <div className={classes.circle}>
            <span>{id}</span>
            </div>
            <img src={'https://yiman.webtm.ru'+text} alt="Картина " />
        </div>
    );
}

