import { LeftOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import styles from "./ArchiveHeader.module.scss";
import { Link } from "react-router-dom";
interface Props {
  button: any;
  setButton: any;
}
export const ArchiveHeader: FC<Props> = ({ button, setButton }) => {
  const buttonClick = (id: number) => {
    setButton(id);
  };
  return (
    <div className={styles.header}>
      {localStorage.getItem("access_token_teacher") ? (
        <h2>
          {" "}
          <Link to={"/teacher/teacherProfil"}>
            <span>
              <LeftOutlined />
            </span>
          </Link>{" "}
          Тесты за четверть
        </h2>
      ) : (
        <h2>
          <Link to={"/student/studentProfile"}>
            <span>
              <LeftOutlined />
            </span>
          </Link>{" "}
          Тесты за четверть
        </h2>
      )}

      <div className={styles.buttons}>
        <div className={button === 1 ? styles.greenButton : styles.button}>
          <button onClick={() => buttonClick(1)}>Четверть 1</button>
        </div>
        <div className={button === 2 ? styles.greenButton : styles.button}>
          <button onClick={() => buttonClick(2)}>Четверть 2</button>
        </div>
        <div className={button === 3 ? styles.greenButton : styles.button}>
          <button onClick={() => buttonClick(3)}>Четверть 3</button>
        </div>
        <div className={button === 4 ? styles.greenButton : styles.button}>
          <button onClick={() => buttonClick(4)}>Четверть 4</button>
        </div>
      </div>
    </div>
  );
};
