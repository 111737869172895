import React, { useEffect, useState } from "react";
import classes from "./TeacherPersonal.module.scss";
import write from "./icons/write.svg";
import {
  setClass,
  setEmail,
  setNameThree,
  setName,
  setLastName,
  setProfile
} from "../../store/slices/profileSlice";
import cloaseIcon from './cross (1) 1.svg'
import { useSelector, useDispatch } from "react-redux";
const TeacherPersonal: React.FC = () => {
  const profile = useSelector((state: any) => state.profile.profile);
  console.log(profile);
  const token = localStorage.getItem('access_token_teacher')
  const [isModal, setisModal] = useState(false)
  const [teacherData, setTeacherData] = useState('Имя')
  const [sendData, setSendData] = useState('')
  const dispatch = useDispatch()
  const [teacher_id,setTeacher_Id] = useState(null)
  // const [isModal,set]
  useEffect(() => {
    fetch('https://yiman.webtm.ru/api/v1/users/teacher/detail/', {
      headers: {
        Authorization: `Bearer ` + token,
      }
    }).then((res) => {
      return res.json()
    }).then((data) => {
      console.log(data);
      setTeacher_Id(data.id)
      dispatch(setProfile({
        name: data.first_name,
        lastName: data.last_name,
        email: data.email,
        middle_name:data.middle_name,
        teacher_class:data.teacher_class
      }))
    })
  }, [])

  const onEdit = async (e: any) => {
    const value = e.target.parentElement.parentElement.parentElement.getAttribute('data-name')
    console.log('value', value);
    setTeacherData(value)


    setisModal(true)

  }
  return (
    <div className={classes.body}>
      {
        profile ?
          <>
            {
              isModal ?
                <div className={classes.modal}>
                  <div className={classes.modal_top}>
                    <p>Ваше {teacherData}</p>
                    <img src={cloaseIcon} alt="" onClick={() => setisModal(false)} />
                  </div>
                  <form onSubmit={async (e) => {

                    e.preventDefault()
                    if (teacherData === 'Имя') {
                      const res = await fetch(`https://yiman.webtm.ru/api/v1/users/teacher/${teacher_id}/`, { method: 'PUT', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ ...profile, first_name: sendData }) })
                      setisModal(false)
                      console.log(res);

                    } else if (teacherData === 'Фамилия') {
                      const res = await fetch(`https://yiman.webtm.ru/api/v1/users/teacher/${teacher_id}/`, { method: 'PUT', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ ...profile, lastName: sendData }) })
                      console.log(res);
                      setisModal(false)

                    } else if (teacherData === 'Отчетво') {
                      const res = await fetch(`https://yiman.webtm.ru/api/v1/users/teacher/${teacher_id}/`, { method: 'PUT', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ ...profile, middle_name: sendData }) })
                      console.log(res);
                      setisModal(false)
                    } else if (teacherData === 'Класс') {
                      const res = await fetch(`https://yiman.webtm.ru/api/v1/users/teacher/${teacher_id}/`, { method: 'PUT', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ ...profile, teacher_class: sendData }) })
                      console.log(res);
                      const data = await res.json()
                      setisModal(false)

                    } else if (teacherData === 'Электронная почта') {
                      const res = await fetch(`https://yiman.webtm.ru/api/v1/users/teacher/${teacher_id}/`, { method: 'PUT', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ ...profile, email: sendData }) })
                      console.log(res);
                      const data = await res.json()
                      setisModal(false)


                    }
                  }}>
                    <input type="text" onInput={(e: any) => setSendData(e.target.value)} />
                  </form>

                </div>
                : ''
            }

            <div className={classes.teacherPersonal}>
              <p>Персональные данные</p>
              <div className={classes.dataPersonal}>
                <div className={classes.info} data-name="Имя">
                  <p className={classes.green}>Имя</p>
                  <div>
                    <p>{profile.name}</p>
                    <button>
                      <img src={write} alt="change" onClick={onEdit} />
                    </button>
                  </div>
                </div>
                <div className={classes.info} data-name="Фамилия">
                  <p className={classes.green} >Фамилия</p>
                  <div>
                    <p>{profile.lastName}</p>
                    <button>
                      <img src={write} alt="change" onClick={onEdit} />
                    </button>
                  </div>
                </div>
                <div className={classes.info} data-name="Отчетво">
                  <p className={classes.green}>Отчетво</p>
                  <div>
                    <p>{profile.middle_name}</p>
                    <button>
                      <img src={write} alt="change" onClick={onEdit} />
                    </button>
                  </div>
                </div>
                <div className={classes.info} data-name="Класс">
                  <p className={classes.green}>Класс</p>
                  <div>
                    <p>{profile.teacher_class}</p>
                    <button>
                      <img src={write} alt="change" onClick={onEdit} />
                    </button>
                  </div>
                </div>
                <div className={classes.info} data-name="Электронная почта">
                  <p className={classes.green}>Электронная почта</p>
                  <div>
                    <p>{profile.email}</p>
                    <button>
                      <img src={write} alt="change" onClick={onEdit} />
                    </button>
                  </div>
                </div>
              </div>
            </div></>
          : ''
      }

    </div>
  );
};

export default TeacherPersonal;
