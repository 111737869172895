import React, { FC, useState } from 'react'
import classes from './login.module.scss'
import ownerLogo from '../../assets/icons/OwnerLogo.svg'
import LoginStep1 from './LoginStep1/LoginStep1'
interface Props{
    messageApi:any
}
const Login: FC<Props> = ({messageApi}) => {
    const [isShow, setIsShow] = useState(false)
    
    return (
        <div className={classes.login}>
            <img src={ownerLogo} alt="" className={classes.ownerLogo} />
           <LoginStep1 messageApi={messageApi}/>
        </div>
    )
}

export default Login