import { FC, useState } from 'react';
import classes from './CreateChoice.module.scss';
import { QuestionCard } from '../../UI'; // Убедитесь, что путь к QuestionCard правильный
import { changeCurrentTest } from '../../../store/slices/createTest';
import { useSelector, useDispatch } from 'react-redux';
import { createAnswer } from '../../../store/slices/createTest';
import { selectTrueAnswer } from '../../../store/slices/createTest';
import { PlusOutlined } from '@ant-design/icons';
interface TestFormProps {
    question: string | null;
}

export const CreateChoice: FC<TestFormProps> = ({ question }) => {
    const [selectedCard, setSelectedCard] = useState<number | null>(null);
    const [isAddedQuest, setIsAddedQuest] = useState<boolean>(false);
    const [newQuestion, setNewQuestion] = useState<string>('');

    const currentTest = useSelector((state: any) => state.createTest.currentTest)
    const currentTestId = useSelector((state: any) => state.createTest.currentTestId)
    console.log('currtest', currentTest);
    const dispatch = useDispatch()
    const [cards, setCards] = useState([{
        text: 'newQuestion',
        id: 1
    }])

    const handleSelectCard = (id: number) => {

        setSelectedCard(id);
    };

    const handleAddQuestion = () => {

        if (newQuestion.trim() !== '') {
            console.log(currentTestId);

            const idx = currentTestId - 1
            const answerId = currentTest.questions[idx].answers.length > 0 ? currentTest.questions[idx].answers.length + 1 : 1

            const payload = {
                idx,
                answer: {
                    id: answerId,
                    answer_text: newQuestion,
                    is_active: false
                }
            }
            dispatch(createAnswer(payload))
            setNewQuestion('');
            setIsAddedQuest(false);
        }
    };

    return (
        <section className={classes.section}>
            {question && <h2 key={94203809809}>{question}</h2>}
            <div className={classes.cardContainer}>
                {currentTest.questions[currentTestId - 1].answers.map(({ id, answer_text,is_active }: any, index: number) => (
                    <QuestionCard
                        key={index}
                        id={id}
                        text={answer_text}
                        onSelect={handleSelectCard}
                        isSelected={is_active}
                        questionArr={cards}
                        setQuestionArr={setCards}
                    />
                ))}
            </div>
            <button onClick={() => setIsAddedQuest(true)} className={classes.btnAdd}>добавить</button>
            {isAddedQuest && (
                <div className={classes.choiceAdded}>
                    <input
                        type="text"
                        value={newQuestion}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewQuestion(e.target.value)}
                    />
                    <button onClick={() => setIsAddedQuest(false)}>закрыть</button>
                    <button onClick={handleAddQuestion}>добавить</button>
                </div>
            )}
        </section>
    );
};
