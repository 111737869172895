import React, { useState } from 'react'
import { FormInput } from '../../components/UI'
import classes from './RegisterTeacher.module.scss'
import ownerLogo from '../../assets/icons/OwnerLogo.svg'
import Error from './icons/Error'

const RegisterTeacher: React.FC = () => {
  const [error, setError] = useState(false)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const isValid = false
    if (!isValid) {
      setError(true)
    }
  }

  return (
    <div className={classes.Register}>
      <img src={ownerLogo} alt='' className={classes.ownerLogo} />
      <h1>Вход</h1>
      <form className={classes.formInput} onSubmit={handleSubmit}>
        {error && (
          <div className={classes.error}>
            <Error />
            <p>Не верная эл.почта или пароль</p>
          </div>
        )}
        <div className={classes.flexInput}>
          {/* <FormInput type='text' labelText='Имя' /> исправить
          <FormInput id='' value='' type='text' labelText='Фамилия' /> исправить*/}
        </div>
        {/* <FormInput id='' value='' type='email' labelText='Электронная почта' /> исправить
        <FormInput id='' value='' type='password' labelText='Пароль' /> исправить
        <FormInput id='' value='' type='text' labelText='Напишите класс в котором вы руководите' /> исправить*/}
        <button className={classes.btn} type='submit'>
          Продолжить
        </button>
      </form>
    </div>
  )
}

export default RegisterTeacher
