import React, { useEffect, useState } from 'react'
import { Header } from '../../components'
import { Table } from 'antd'
import classes from './TestAbut.module.scss'

function addNumeration(arr:any) {
  return arr.map((item:any, index:any) => {
    return {
      ...item,
      numeration: index + 1,
    };
  });
}

const PrintPage = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    fetch('https://yiman.webtm.ru/api/v1/users/students/rating/')
      .then((res) => res.json())
      .then((data) => {
        const result = addNumeration(data);
        setData(result);
      });
  }, []);

  const handleFilterChange = (event:any) => {
    setFilter(event.target.value);  
  };

  let filteredData = filter
    ? data.filter((item:any) => item.completed_class === filter)
    : data;

  filteredData = addNumeration(filteredData)

  const columns = [ 
    {
      title: 'Место',
      dataIndex: 'numeration',
      key: 'numeration',
    },
    {
      title: 'ФИО',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Класс',
      dataIndex: 'completed_class',
      key: 'completed_class',
    },
    {
      title: 'Общий балл',
      dataIndex: 'total_score',
      key: 'total_score',
    },
  ];

  return (
    <div>
      <div className="container">
        {/* <h1 style={{ textAlign: 'start',width:'940px',margin:'0 auto' }}>Результаты</h1> */}
        <br />
        <Table
          dataSource={filteredData}
          columns={columns}
          style={{ maxWidth: '940px', margin: '0 auto' }}
          rowKey="id"
          pagination={{
            pageSize:20,
          }}
        />
      </div>
    </div>
  );
};

export default PrintPage;
