import { FC, useState } from 'react'
import classes from './TestChoice.module.scss';
import { QuestionCard } from '../../UI';
import { QuestionTestCard } from '../../UI/QuestionTestCard/QuestionTestCard';
import { useSelector, useDispatch } from 'react-redux';
import { addResultTest } from '../../../store/slices/TestSlice';
import answers from '../../../store/slices/answers';
import TestImageCardBig from '../../TestImageCardBig/TestImageCardBig'
import TestImageExmCard from '../../TestImageExmCard/TestImageExmCard'
import TestVideoCardBig from '../../TestVideoCardBig/TestVideoCardBig'
import TestVideoExmCard from '../../TestVideoExmCard/TestVideoExmCard'
interface TestFormProps {
  questionArr: any[];
  question: string | null;
  result: any;
  setResult: any;
  id?: number,
  isTest?: boolean,
  test:any
}

export const TestChoice: FC<TestFormProps> = ({ questionArr, question, result, setResult, id, isTest,test }) => {
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const [isTrue, setIsTrue] = useState<number | null>(null);
  const dispatch = useDispatch()
  console.log(questionArr, 'questionArr');

  const handleSelectCard = (idCard: number) => {
    if (selectedCard === null) {
      setSelectedCard(idCard);
    } else {
      return
    }
    console.log('idCard', idCard);
    let newAnswers: any = []
    questionArr.forEach((item, index) => {
      console.log(item);
      if (item.id === idCard) {
        newAnswers.push({
          answer_text: questionArr[index].answer_text,
          is_active: true,
          type:'radio',
          file:null 
        })
      } else {
        newAnswers.push({
          answer_text: questionArr[index].answer_text,
          is_active: false,
          type:'radio',
          file:null 
        })
      }
    })
    dispatch(addResultTest({
      id,
      answers: newAnswers,
      type:'radio'
    }))


    // questionArr.forEach((item: any) => {

    //   if (item.id === idCard) {
    //     let is = false
    //     // result.forEach((item: any) => {
    //     //   if (selectedCard === item.answer) {
    //     //     is = true
    //     //   }
    //     // })


    //       console.log(result);

    //       const newanswers = {
    //         answers
    //       }

    //       // setResult(newarr)


    //   }
    // })
  };
  return (

    <section className={classes.section}>
     
          
      {
        test.files.length !== 0 ?
          test.files.length === 1 ?
            <div className={classes.quest_wrap}>
              <h2>{question}</h2>
              {
                test.files[0].type ==='photo'?
                <TestImageCardBig url={test.files[0].file} />
                :<TestVideoCardBig url={test.files[0].file}/>
              }
            </div>

            : 
            <div className={classes.quest_wrap}>
              <h2>{question}</h2>
              {
                test.files[0].type ==='photo'?
                <TestImageExmCard files={test.files} />
                :<TestVideoExmCard files={test.files}/>
              }
            </div>

             : <h2>{question}</h2>
      }
  
      
      <div className={classes.cardContainer}>
        {questionArr.map(({ id, answer_text }) => (
          <QuestionTestCard
            type={'test'}
            key={id}
            id={id}
            text={answer_text}
            onSelect={handleSelectCard}
            isSelected={selectedCard === id}
          />
        ))}
      </div>

    </section>
  )
};