import React from 'react'
import classes from './TestImageExmCard.module.scss'

interface Props{
    files:any,

}
const TestImageExmCard:React.FC<Props> = ({files}) => {
  return (
    <div className={classes.cardContainer}>
        {
            files.map((file:any)=>{
                return <div className={classes.card}><span className={classes.card_number}>{file.id}</span>
                <img src={'https://yiman.webtm.ru'+file.file}/>
                </div>
            })
        }
    </div>
  )
}

export default TestImageExmCard