import React from 'react';
import ReactDOM from 'react-dom/client';
import Routs from './routes/index'
import './style.css'
import {store} from './store/store';
import {Provider} from 'react-redux';
 
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
        <Routs/>
    {/* </React.StrictMode> */}
  </Provider>
);