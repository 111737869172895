import { UploadOutlined } from "@ant-design/icons";
import { FC, useEffect, useState } from 'react';
import style from './InputsFile.module.scss';
import { useSelector } from "react-redux";
interface InputVideoFileProps {
    accept: string,
    onChange:any,
    index:any
}

function base64ToBlob(base64:string, mimeType:string) {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
}
export const InputsVideoFile: FC<InputVideoFileProps> = ({accept,onChange,index}) => {
    const [fileURL, setFileURL] = useState<any>(null);
    const currentTest = useSelector((state: any) => state.createTest.currentTest)
    const currentTestId = useSelector((state: any) => state.createTest.currentTestId)
    const onHandlerChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            onChange(file)

            setFileURL(URL.createObjectURL(file));
        }
    };
    useEffect(() => {
        const filesTest = currentTest.questions[currentTestId - 1].files
        console.log(filesTest, 'fileTest');
        console.log('index',index);
        
        if (filesTest.length && filesTest[0].type==='video') {
            if(filesTest[index]){
            console.log( filesTest[index]);
            let base64String = filesTest[index].file.replace("data:text/plain;base64,", "");
                console.log('b64str',base64String);
                
                const blob = base64ToBlob(base64String,'video/mp4')
                let blobUrl = URL.createObjectURL(blob);
                console.log(blobUrl);
                
                setFileURL(blobUrl);
            }

        }
    }, [])
    useEffect(() => {
        return () => {
            if (fileURL) {
                URL.revokeObjectURL(fileURL);
            }
        };
    }, [fileURL]);

    return (
        <div className={style.input_file}>
            <label className={style.input_label}>
                <input type="file" className={style.input_element} accept={accept} onChange={onHandlerChange}/>
                <span className={style.input_span}> {fileURL ? '' : (<UploadOutlined style={{fontSize: '60px', color: '#248B00', marginBottom: '32px'}} /> )}     
                   {fileURL ? '' : (<p>Перетяните сюда видое</p> )}
                </span>
            </label>
            {fileURL && <video src={fileURL} controls className={style.preview_image} />}
        </div>
    );
}
