import { FC, useState } from "react";
import style from './SearchHeader.module.scss'
import emblema from '../../../assets/icons/OwnerLogo.svg'
import { FileDoneOutlined, QuestionCircleOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import iconAllTest from '../../../assets/icons/test 1.png';
import {Link} from "react-router-dom";
interface Props{
    setSearchTerm:any,
    searchTerm:any
}
export const SearchHeader: FC<Props> = ({searchTerm,setSearchTerm}) => {
    const [active, setActive] = useState<number>();

    const handlerButton = (id: number) => {
        setActive(id);
        console.log(id);

    }
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };



    return (
        <div className={style.cont}>
            <div className={style.flexCont}>
                <div className={style.img}>
                    <img src={emblema} alt="" />
                </div>
                <div className={style.search}>
                    <SearchOutlined style={{ fontSize: '29px' }} />
                    <input type="text" placeholder='Поиск' onInput={handleSearch} />
                </div>
                <div className={style.userIcon}>
                    <UserOutlined style={{ fontSize: '27px', color: '#248B00' }} />
                </div>
            </div>

            <div className={style.category}>
                <div className={style.flexCont}>
                    <div className={style.allTest}>
                        <button className={active === 1 ? style.activeButton : ''} onClick={() => handlerButton(1)}><img src={iconAllTest} alt="" /> Все тесты</button>
                    </div>
                    <div>
                        <Link to="/createTest" style={{textDecoration:'none'}}> <button className={active === 2 ? style.activeButton : ''} onClick={() => handlerButton(2)}><QuestionCircleOutlined style={{ fontSize: '28px', paddingRight: '4.5px' }} /> Вопросы</button></Link>
                    </div>
                    <div>
                        <button className={active === 3 ? style.activeButton : ''} onClick={() => alert('Эта страница еще в разработке')}><FileDoneOutlined style={{ fontSize: '28px', paddingRight: '4.5px' }} /> Результаты</button>
                    </div>
                </div>

            </div>
        </div>
    );
}

