import React, { useEffect, useState } from "react";
import classes from "./FinishTest.module.scss";
import Quation from "./icons/Quation";
import Clock from "./icons/Clock";
import Important from "./icons/Important";
import True from "./icons/True";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
const FinishTest: React.FC = () => {
  const [useOriginalIcons, setUseOriginalIcons] = useState(true);
  const navigate = useNavigate()
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1194) {
        setUseOriginalIcons(true);
      } else {
        setUseOriginalIcons(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  if (!localStorage.getItem('access')) {
    return <Navigate to={'/login'} />
  }
  return (
    <div className={classes.finishTest}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.blockHeader}>
           
            <div className={classes.flex}>
              {useOriginalIcons ? <Clock /> : <True />}
              <h3>У вас есть время: 130 мин</h3>
            </div>
            <div className={classes.flex}>
              {useOriginalIcons ? <Important /> : <True />}
              <h3>Обязательный тест</h3>
            </div>
          </div>
        </div>
        {/* <div className={classes.footerContainer}>
          <div className={classes.block}>
            <p>Экзаменационный тест по экономике</p>
          </div>
        </div> */}
      </div>
      <p className={classes.answerBlock}>Ваши ответы отправлены!</p>
      <button onClick={() => {
        navigate('/testResult')
      }}>Продолжить</button>
    </div>
  );
};

export default FinishTest;
