import { FC, useState } from "react";
import classes from './TestImg.module.scss';
import { ImgCard } from "./ImgCard/ImgCard";
import { useDispatch } from "react-redux";
import { addResultTest } from "../../../../../store/slices/TestSlice";
import TestImageCardBig from "../../../../TestImageCardBig/TestImageCardBig";
import TestImageExmCard from "../../../../TestImageExmCard/TestImageExmCard";
import TestVideoExmCard from "../../../../TestVideoExmCard/TestVideoExmCard";
import TestVideoCardBig from "../../../../TestVideoCardBig/TestVideoCardBig";
interface TestImgProps {
  questionArr: any[];
  question: string | null;
  result: any;
  setResult: any;
  id?: number;
  test:any
}

export const TestImg: FC<TestImgProps> = ({ questionArr, question, result, setResult, id,test }) => {
    const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const dispatch = useDispatch()

    const handleSelectCard = (idCard: number) => {
    
    if (selectedCard === null) {
      setSelectedCard(idCard);
    }
    else {
      return
    }
    console.log('idCard', idCard);
    let newAnswers: any = []
    questionArr.forEach((item, index) => {
      console.log(item);
      if (item.id === idCard) {
        newAnswers.push({
          answer: questionArr[index].answer,
          is_active: true,
          type:'Photo'

        })
      } else {
        newAnswers.push({
          answer: questionArr[index].answer,
          is_active: false,
          type:'Photo'
        })
      }
    })
    dispatch(addResultTest({
      questions:question,
      answers: newAnswers,
      type: 'Photo'
    }))

  };

    return (
        <section className={classes.section}>


{
        test.files.length !== 0 ?
          test.files.length === 1 ?
            <div className={classes.quest_wrap}>
              <h2>{question}</h2>
              {
                test.files[0].type ==='photo'?
                <TestImageCardBig url={test.files[0].file} />
                :<TestVideoCardBig url={test.files[0].file}/>
              }
            </div>

            : 
            <div className={classes.quest_wrap}>
              <h2>{question}</h2>
              {
                test.files[0].type ==='photo'?
                <TestImageExmCard files={test.files} />
                :<TestVideoExmCard files={test.files}/>
              }
            </div>

             : <h2>{question}</h2>
      }

      {/* {
        question !== '' ? (
          <h2>{question}</h2>
        ) : ''
      } */}
      <div className={classes.cardContainer}>
        {questionArr.map(({ id, file }) => (
          <ImgCard
            type={'test'}
            key={id}
            id={id}
            text={file}
            onSelect={handleSelectCard}
            isSelected={selectedCard === id}
          />
        ))}
      </div>

    </section>
    );
}

