import { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import style from './StudentsTests.module.scss';
import { Link } from 'react-router-dom';
interface DataType {
  key: string;
  title: string;
  lastUse: string;
  subject: string;
  lastChange: string;
  create: string;
}

const columns: TableProps<DataType>['columns'] = [{
  title: 'id',
  dataIndex: 'id',
  key: 'id',
  render: (text) => <Link to={'/test/'+text}>{text}</Link>,
},
{
  title: 'Фио',
  dataIndex: 'teacher',
  key: 'teacher',
},
{
  title: 'Четверть',
  dataIndex: 'choise',
  key: 'choise',
},
{
  title: 'Загаловок',
  dataIndex: 'title',
  key: 'title',
},
];

const data: DataType[] = [
  {
    key: '',
    title: 'yu',
    lastUse: '',
    subject: '',
    lastChange: '',
    create: '',
  },
];
interface Props {
  searchTerm: any,
  setSearchTerm: any
}
export const StudentsTestsTable: FC<Props> = ({ searchTerm, setSearchTerm }) => {
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('https://yiman.webtm.ru/api/v1/users/student/tests/', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`
      }
    }).then((res) => {
      return res.json()
    }).then((data) => {
      console.log(data);

      setData(data)
    })
  }, [])
  console.log(data);
  const filteredData = data.filter((item: any) =>
    item.teacher.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <section className={style.table}>
      <Table columns={columns} dataSource={filteredData} />
    </section>
  );
}

