import { FC } from 'react'
import classes from './TestHeader.module.scss';
import QuestionMark from '../../../assets/icons/question-mark.svg';
import Clock from '../../../assets/icons/clock-three.svg';
import Exclamation from '../../../assets/icons/exclamation.svg';
import { string } from 'yup';
interface Props{
    title:string
}
export const TestHeader: FC<Props> = ({title}) => {
  return (
    <header className={classes.header}>
        <div className={classes.header__top}>
            <ul className={classes.header__info}>
                {/* <li className={classes.header__info_item}>
                    <img src={QuestionMark} alt="questionmark" />
                    <p>5/20 вопросов по экономике</p>
                </li> */}
                <li className={classes.header__info_item}>
                    <img src={Clock} alt="Clock" />
                    <p>У вас есть время: 130 мин</p>
                </li>
                <li className={classes.header__info_item}>
                    <img src={Exclamation} alt="exclamation" />
                    <p>Обязательный тест</p>
                </li>
            </ul>
        </div>
        <div className={classes.header__title}>
            <h1>{title}</h1>
        </div>
    </header>
  )
};