import React from 'react'
import classes from './TestImageCardBig.module.scss'
interface Props{
    url:string
}
const TestImageCardBig:React.FC<Props> = ({url}) => {
  return (
    <div className={classes.testImageCardBig}>
        <img src={'https://yiman.webtm.ru'+url} alt="" />
    </div>
  )
}

export default TestImageCardBig