import { FC, useEffect,useState } from "react";
import { ArchiveHeader } from "../../components/TestArchives";
import style from './TestArchive.module.scss';
import { ResultMark } from "../../components/TestResult";
import { ArchiveHeaderAbut } from "../../components/ArchiveHeaderAbut/ArchiveHeader";
export const TestArchiveAbut: FC = () => {
    const [data,setData] = useState<any>([])
    const [button, setButton] = useState<number | null>(1);

    useEffect(()=>{
        fetch('https://yiman.webtm.ru/api/v1/tests/tests_result/',{
            headers:{
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        }).then(async(res)=>{
            const data = await res.json()
            console.log(data);
            setData(data)
        })
    },[])
    return (
        <div className={style.container}>
            <ArchiveHeaderAbut button={button} setButton={setButton}/>
            {
                data?.map((item:any,index:any)=>{
                    console.log(button,'button');
                    
                    if(button===1 && index===0){
                        return  <ResultMark progress={(data[0].score)} result_answers={data[0]} />
                    }
                    if(button===2 && index===1){
                        return  <ResultMark progress={(data[1].score)} result_answers={data[1]} />
                    }
                    if(button===3 && index===2){
                        return  <ResultMark progress={(data[2].score)} result_answers={data[2]} />
                    }
                    if(button===4 && index===3){
                        return  <ResultMark progress={(data[3].score)} result_answers={data[3]} />
                    }
                })
            }
        </div>
    );
}

