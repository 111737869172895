import React from 'react'
import classes from './TestTextFild.module.scss'
import { useDispatch } from 'react-redux'
import { addResultTest } from '../../../../../store/slices/TestSlice'
import TestImageCardBig from '../../../../TestImageCardBig/TestImageCardBig'
import TestImageExmCard from '../../../../TestImageExmCard/TestImageExmCard'
import TestVideoCardBig from '../../../../TestVideoCardBig/TestVideoCardBig'
import TestVideoExmCard from '../../../../TestVideoExmCard/TestVideoExmCard'
interface TestTextFieldProps {
  id: number,
  questions: string,
  result: any;
  setResult: any;
  test: any
}

export const TestTextField: React.FC<TestTextFieldProps> = ({ id, questions, test }) => {
  const dispatch = useDispatch()
  console.log(id,'sdof');
  
  const onHandler = (e: any) => {
    dispatch(addResultTest({
      id,
      answers: [{
        answer_text: e.target.value,
        file: null,
        is_active: false,
        type: 'text'
      }],
      type: 'text'
    }))
  }
  console.log(test);
  console.log(test.files.length !== 0);

  return (
    <div className={classes.textField}>

      {
        test.files.length !== 0 ?
          test.files.length === 1 ?
            <div className={classes.quest_wrap}>
              <h2>{questions}</h2>
              {
                test.files[0].type ==='photo'?
                <TestImageCardBig url={test.files[0].file} />
                :<TestVideoCardBig url={test.files[0].file}/>
              }
            </div>

            : 
            <div className={classes.quest_wrap}>
              <h2>{questions}</h2>
              {
                test.files[0].type ==='photo'?
                <TestImageExmCard files={test.files} />
                :<TestVideoExmCard files={test.files}/>
              }
            </div>

             : <h2>{questions}</h2>
      }

      <textarea placeholder='Напишите ответ' onInput={onHandler}></textarea>

    </div>
  )
}
