import { FC, useState } from "react";
import style from './AddedTestCard.module.scss';
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import { deleteQuestion, setCurrentTestId } from "../../../../store/slices/createTest";
import { useDispatch } from "react-redux";
interface AddedTestCardProps {
    id: number;
    title: string;
}

export const AddedTestCard: FC<AddedTestCardProps> = ({id, title}) => {
    console.log(id,title);
    const [flag, setFlag] = useState<boolean>();

    const setCur = (id: number) => {
        if(flag === true){
            disptach(setCurrentTestId(0))
            setFlag(false)
        }else{
            disptach(setCurrentTestId(id))
        }

    }
    
    const disptach = useDispatch()
    return (
        <div className={style.card} data-id={id} onClick={(e:any)=>{
            setCur(id)
        }}>
            <div className={style.headerCard}>
                <div>Вопрос #{id}</div>
                <div className={style.headerCard}>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        setFlag(true);
                        disptach(setCurrentTestId(0)) 
                        disptach(deleteQuestion({id: id}))
                    }}><CloseOutlined /></button> 
                        {/* <div><CopyOutlined /></div>  */}
                </div>
                
            </div>

            <div className={style.title}>
                <p>{id}.</p>
                <p>{title}</p>
            </div>
        </div>
    );
}
