import React from "react";
import { Header } from "../../components";
import classes from "./TeacherProfil.module.scss";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TeacherPersonal from "../TeacherPersonal/TeacherPersonal";
const TeacherProfil: React.FC = () => {
  const dispatch = useDispatch()
  // if (!localStorage.getItem('access_token_teacher')) {
  //   return <Navigate to={'/teacherLogin'} />
  // }
  return (
    <div className={classes.TeacherProfil}>
      <Header />
      <Outlet />
      <TeacherPersonal />
    </div>
  );
};

export default TeacherProfil;