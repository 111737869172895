import React from 'react';
import classes from './questionCard.module.scss';
import { useSelector,useDispatch } from 'react-redux';
import { deleteAnswer ,selectTrueAnswer} from '../../../store/slices/createTest';
interface QuestionCardProps {
    id: number;
    text: string;
    onSelect: any;
    isSelected: boolean;
    questionArr?: any
    setQuestionArr?:any
    type?:any
}

const QuestionCard: React.FC<QuestionCardProps> = ({ id, text, onSelect, isSelected, questionArr, setQuestionArr,type,...props }) => {
    const dispatch = useDispatch()
    const handleClick = () => {
       dispatch(selectTrueAnswer({
            idx:currentTestId-1,
            answerId:id,
            type:'radio'
        }))
        onSelect(id);
    };
    const currentTest = useSelector((state:any)=>state.createTest.currentTest)
    const currentTestId = useSelector((state:any)=>state.createTest.currentTestId)

    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation(); 
        dispatch(deleteAnswer({
            idx:currentTestId-1,
            answerId:id
        }))
    };

    return (
        <div className={`${classes.card} ${isSelected ? classes.selected : ''}`}{...props} onClick={handleClick}>
            <p className={`${classes.text} ${isSelected ? classes.selected : ''}`}>{text}</p>
            <div className={`${classes.circle} ${isSelected ? classes.selected : ''}`}>
                <span>{id}</span>
            </div>
          {
          type==='test'? '': <div className={classes.delete} onClick={handleDelete}>delete</div>
          }  
        </div>
    );
}

export default QuestionCard;
