import { FC, useState, useEffect } from "react";
import style from './CreateTest.module.scss';
import { Radio, RadioChangeEvent, Select, Space } from "antd";
import { TestImage, TestManyChoice, TextField, CreateChoice } from "../../Tests";
import { useSelector, useDispatch } from "react-redux";
import { setQuestionTitle, setTypeQuestion, addFile, setFilesCount,setFilesType,setPassingScore,setMaksScore } from "../../../store/slices/createTest";
import { TestVideo } from "../../Tests";
import { InputFile } from "./InputFile/InputFile";
import { InputsFile } from "./InputsFile/InputsFile";
import InputVideoFile from "./InputFile/InputVideoFile";
import { InputsVideoFile } from "./InputsFile/InputsVideoFile";

import { createAnswer, clearFiles } from "../../../store/slices/createTest";
import { log } from "console";
export const CreateTests: FC = () => {

    const currentTest = useSelector((state: any) => state.createTest.currentTest)
    const currentTestId = useSelector((state: any) => state.createTest.currentTestId)
    console.log('currtest', currentTest);

    // const [files,setFiles] = useState<any>({
    //     file:null,
    //     type:null
    // })

    const dispatch = useDispatch()

    function getType() {

        const type = currentTest.questions.length ? currentTest.questions[currentTestId - 1].type : ''
        console.log(type, 't');

        if (type === 'Photo') {
            return <TestImage question={''} />
        } else if (type === 'radio') {
            return <CreateChoice question={''} />
        } else if (type === 'checkbox') {
            return <TestManyChoice question={''} />
        } else {
            return <TextField value={currentTest.questions[currentTestId-1].answers[0]?currentTest.questions[currentTestId-1].answers[0].answer_text:''}  onChange={(e: any) => {
                const idx = currentTestId - 1
                const answerId = currentTest.questions[idx].answers.length > 0 ? currentTest.questions[idx].answers.length + 1 : 1

                const payload = {
                    idx,
                    answer: {
                        type: 'text',
                        id: answerId,
                        answer: e.target.value,
                        is_active: true
                    }
                }
                dispatch(createAnswer(payload))
            }} />
        }
    }

    console.log(currentTestId);


    const [file, setFile] = useState<any>(null);
    const [count, setCount] = useState<number>(0);
    const [photo, setPhoto] = useState<any>();
    const [typeFilesLocal, setTypeFilesLocal] = useState('photo')
    const [video, setVideo] = useState<any>();
    const [files, setFiles] = useState({
        type: 'photo',
        files: []
    })

    console.log('filesInfo', {
        file,
        count,
        files
    });

    useEffect(() => {
        if (currentTest.questions[currentTestId - 1]) {
            console.log(currentTest.questions[currentTestId - 1]);

            const length = currentTest.questions[currentTestId - 1].files.length
            console.log('-------------------------------------------------------->', length);

            setCount(length)
        }
    }, [currentTestId])

    useEffect(() => {


    }, [typeFilesLocal])
    useEffect(() => {

        if (files.files.length) {
            dispatch(addFile({ idx: currentTestId - 1, files }))
        }
    }, [files])
    const handleChangePhoto = (value: string) => {
        console.log(`selected photo ${value}`);
        setPhoto(value);
        setVideo('Видео');
        setFile('.png,.jpg,.jpeg');
        setCount(Number(value))
        dispatch(clearFiles({ idx: currentTestId - 1 }))
        setFiles({ files: [], type: 'photo' })
        setTypeFilesLocal('photo')
        dispatch(setFilesType({idx:currentTestId-1,type:'photo'}))
        dispatch(setFilesCount({idx:currentTestId-1,count:+value}))
    };

    const handleChangeVideo = (value: string) => {
        console.log(`selected video ${value}`);
        setPhoto('Фото');
        setVideo(value);
        setFile('.MP4,.MKV,.WEBM');
        setCount(Number(value))
        dispatch(clearFiles({ idx: currentTestId - 1 }))
        setFiles({ files: [], type: 'video' })
        setTypeFilesLocal('video')
        dispatch(setFilesType({idx:currentTestId-1,type:'video'}))
        dispatch(setFilesCount({idx:currentTestId-1,count:+value}))

    };

    const handleChangeQustion = (value: number) => {
        console.log(`selected ${value}`);
        dispatch(setTypeQuestion({ idx: currentTestId - 1, type: value }))
    };

    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
    };

    const collInputs = () => {
        return (
            <div className={style.inputs}>
                <TestVideo question={''} />
            </div>
        )
    }
    console.log('files', files);

    const component = (type: any | null) => {
        if (type === 'Photo') {
            return <TestImage question={''} />
        } else if (type === 'radio') {
            return <CreateChoice question={''} />
        } else if (type === 'checkbox') {
            return <TestManyChoice question={''} />
        } else if (type === 'video') {
            return collInputs()
        } else {
            return <TextField value={currentTest.questions[currentTestId-1].answers[0]?currentTest.questions[currentTestId-1].answers[0].answer_text:''} onChange={(e: any) => {
                const idx = currentTestId - 1
                const answerId = currentTest.questions[idx].answers.length > 0 ? currentTest.questions[idx].answers.length + 1 : 1

                const payload = {
                    idx,
                    answer: {
                        type: 'text',
                        id: answerId,
                        answer_text: e.target.value,
                        is_active: false
                    }
                }
                dispatch(createAnswer(payload))
            }} />
        }
    }

    const changeTitle = (e: any) => {
        dispatch(setQuestionTitle({
            idx: currentTestId - 1,
            title: e.target.value
        }))

    }
    
    if (currentTestId === 0) {
        return <></>
    }
    console.log(currentTest.questions[currentTestId - 1]);
    const getTypeFiles = ()=> currentTest.questions[currentTestId-1].files_type
    let isIf = (type: any) => {
        
        return currentTest.questions[currentTestId - 1].files.length ? currentTest.questions[currentTestId - 1].files[0].type == type : true
}
    console.log(isIf('photo'), isIf('video'), isIf('photo'), isIf('video'));

    return (
        <div className={style.box}>
            <p className={style.question}>Заголовок вопроса</p>

            <div className={style.title}>
                <h2><span>{currentTestId}.</span><input type="text" value={currentTest.questions[currentTestId - 1].questions} onInput={changeTitle} /></h2>
            </div>
            <div className={style.choiceQuestion}>
                <div><p>Визуализация вопроса</p></div>
                <div>
                    {
                        currentTest.questions[currentTestId-1].files_count>-1 ?
                        <Space wrap>
                        <Select
                            defaultValue="Фото"
                            value={currentTest.questions[currentTestId-1].files_count}
                            style={{ width: 130, height: 45 }}
                            onChange={handleChangePhoto}
                            options={[
                                { value: '0', label: '0' },
                                { value: '1', label: '1' },
                                { value: '2', label: '2' },
                                { value: '3', label: '3' },
                                { value: '4', label: '4' },
                            ]}
                        />
                        <Select
                            defaultValue="Видео"
                            value={currentTest.questions[currentTestId-1].files_count}
                            style={{ width: 130, height: 45 }}
                            onChange={handleChangeVideo}
                            options={[
                                { value: '0', label: '0' },
                                { value: '1', label: '1' },
                                { value: '2', label: '2' },
                                { value: '3', label: '3' },
                                { value: '4', label: '4' },
                            ]}
                        />
                    </Space>
                    :''
                    }
        
                </div>
            </div>

            <div className={style.inputs}>
                {
                    currentTest.questions[currentTestId-1].files_count === 1 && getTypeFiles()==='photo' ? <InputFile accept={'.png,.jpg,.jpeg'} index={0} onChange={(file: any) => {
                        // setFiles({ ...files, })
                        const reader: any = new FileReader();
                        console.log(file, 'file');

                        reader.onload = function () {
                            const base64String = reader.result.split(',')[1];
                            console.log(base64String);
                            const filesNew = JSON.parse(JSON.stringify(files.files))
                            filesNew.push({ file: 'data:text/plain;base64,' + base64String, type: 'photo' })
                            setFiles({ ...files, files: filesNew })
                        };
                        reader.readAsDataURL(file);
                        console.log('files', files);


                    }} />
                        : currentTest.questions[currentTestId-1].files_count === 1 && getTypeFiles()==='video' ? <InputVideoFile accept={'.MP4,.MKV,.WEBM'} index={0} onChange={(file: any) => {
                            // setFiles({ ...files, })
                            const reader: any = new FileReader();
                            reader.onload = function () {
                                const base64String = reader.result.split(',')[1];
                                console.log(base64String);
                                const filesNew = JSON.parse(JSON.stringify(files.files))
                                filesNew.push({ file: 'data:text/plain;base64,' + base64String, type: 'video' })

                                setFiles({ ...files, files: filesNew })

                            };
                            reader.readAsDataURL(file);
                            console.log('files', files);


                        }} />
                            : getTypeFiles()==='photo' ?
                                Array.from({ length: currentTest.questions[currentTestId-1].files_count }).map((_, index) => (
                                    <InputsFile key={index} index={index} accept={'.png,.jpg,.jpeg'} onChange={(file: any) => {
                                        // setFiles({ ...files, }) 
                                        const reader: any = new FileReader();
                                        reader.onload = function () {
                                            const base64String = reader.result.split(',')[1];
                                            console.log(base64String);
                                            const filesNew = JSON.parse(JSON.stringify(files.files))
                                            filesNew.push({ file: 'data:text/plain;base64,' + base64String, type: 'photo' })

                                            console.log(filesNew, 'Filesnew');

                                            setFiles({ ...files, files: filesNew, type: 'photo' })

                                        };
                                        reader.readAsDataURL(file);
                                        console.log('files', files);

                                    }} />
                                )) :
                                Array.from({ length: currentTest.questions[currentTestId-1].files_count }).map((_, index) => (
                                    <InputsVideoFile key={index} accept={'.MP4,.MKV,.WEBM'} index={index} onChange={(file: any) => {
                                        // setFiles({ ...files, })
                                        const reader: any = new FileReader();
                                        console.log(file, 'file');

                                        reader.onload = function () {
                                            const base64String = reader.result.split(',')[1];
                                            console.log(base64String);
                                            const filesNew = JSON.parse(JSON.stringify(files.files))
                                            filesNew.push({ file: 'data:text/plain;base64,' + base64String, type: 'video' })

                                            setFiles({ ...files, files: filesNew })

                                        };
                                        reader.readAsDataURL(file);
                                        console.log('files', files);

                                    }} />
                                ))
                }
            </div>

            <div className={style.questionType}>
                <p>Тип ответа</p>
                <Space wrap>
                    <Radio.Group onChange={onChange} value={currentTest.questions[currentTestId - 1].type}>
                        <Select
                            // defaultValue="Развернутый ответ"
                            placeholder='Развернутый ответ'
                            // style={{ width: 511, height: 60 }}
                            className={style.select}
                            onChange={handleChangeQustion}
                            options={[
                                { value: 'text', label: <Radio value={'text'}>Развернутый ответ</Radio> },
                                { value: 'radio', label: <Radio value={'radio'}>Вариантами (один ответ)</Radio> },
                                { value: 'checkbox', label: <Radio value={'checkbox'}>Несколько текстовых ответов (квадраты)</Radio> },
                                {
                                    value: 'Photo', label: <Radio value={'Photo'}>Картинками <span className={style.coll}>Кол.во</span>
                                    </Radio>
                                },
                                // {
                                //     value: 'video', label: <Radio value={'video'}>Видео <span className={style.coll}>Кол.во</span>
                                //     </Radio>
                                // },
                            ]}
                        />
                    </Radio.Group>
                </Space>
            </div>

            <div className={style.answers}>
                {currentTest.questions.length ?
                    component(currentTest.questions[currentTestId - 1].type) :
                    ''
                }
            </div>

            {/* <div className={style.saveButton} onClick={()=>dispatch(setCurrentTestId(currentTestId+1))} >
                <button>Сохранить</button>
            </div> */}
        </div>
    );
}

