import { FC } from 'react';
import classes from './imageCard.module.scss';
import { useDispatch,useSelector } from 'react-redux';
import { deleteAnswer } from '../../../store/slices/createTest';

interface ImageCardProps {
    id: number;
    imgURL: string;
    onSelect: (id: number) => void;
    isSelected: boolean;
    setQuestionArr:any;
    questionArr:any
  }

const ImageCard: React.FC<ImageCardProps> = ({ id,  imgURL, onSelect, isSelected, questionArr ,setQuestionArr}) => {

const dispatch = useDispatch()
const currentTestId = useSelector((state: any) => state.createTest.currentTestId)

  const handleClick = () => {
      onSelect(id);
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(deleteAnswer({
      idx:currentTestId-1,
      answerId:id
  }))
  };
  const dataUrl = imgURL
  return (
    <div className={`${classes.card} ${isSelected ? classes.selected : ''}`} onClick={handleClick}>
        <div className={classes.circle}>
         <span>{id}</span>
        </div>
        <img src={dataUrl} alt="Картина " />
      <div className={classes.delete} onClick={handleDelete}>delete</div>

    </div>

  );
}

export default ImageCard;
