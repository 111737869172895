import React from 'react'
import logoHeader from "./icons/logoHeader.svg";
import profile from "./icons/profile.svg";
import test from "./icons/test.svg";
import quation from "./icons/quatition.svg";
import results from './icons/results.svg'
import { Link } from 'react-router-dom';
import classes from './studentHeader.module.scss'

const studentHeader: React.FC = () => {
    return (
        <div>
            <div className={classes.header}>
                <div className={classes.topHeader}>
                    <img src={logoHeader} alt="logo" />

                    <div>
                    <Link to="/student/abutArchiv" style={{textDecoration:'none',color:'black'}}>  <button className={classes.btn}>
          
          <span>Результаты абитуриента</span>
        </button></Link>   
                    </div>
                    
                    <img src={profile} alt="profile" />
                </div>
                {/* <div className={classes.bottomHeader}>
                    <button className={classes.btn}>
                        <img src={test} alt="test" />
                        <span>Все тесты</span>
                    </button>
                    <button className={classes.btn}>
                        <img src={quation} alt="quation" />
                        <span>Вопросы</span>
                    </button>
                    <button className={classes.btn}>
                        <img src={results} alt="results" />
                        <span>Результаты</span>
                    </button>
                </div> */}
            </div>
        </div>
    )
}

export default studentHeader