import { FC, useEffect, useState } from "react";
import { AddedTest, CreateTests, SearchHeader } from "../../components/CreateTest";
import style from './CreateTest.module.scss';
import { FormInput } from "../../components/UI";
import { useSelector, useDispatch } from "react-redux";
import { createTest, changeCurrentTest, setTitleQuest,setPassingScore,setMaksScore,setClass } from "../../store/slices/createTest";
import { Navigate } from "react-router-dom";
import { Header } from "../../components";
// import setTestL
export const CreateTest: FC = () => {
    const [title, setTitle] = useState('')
    const dispatch = useDispatch()
    const currentTest = useSelector((state: any) => state.createTest.currentTest)
    console.log('ascasc124214',currentTest);
    
    console.log();
    
    const testInitConfig:any = {
        title: '',
        passing_score: 0,
        max_score:0,
        choise: '1-четверть',
        teacher: +localStorage.getItem('teacher_id')!,
            questions: [
                // {
                //     id:1,
                //     type:'',
                //     questions:'',
                //     files:'',
                //     answers:[
                //         {
                //             id:1,    
                //             answer:'',
                //             is_active:false
                //         }
                //     ]
                // }
            ]
        
    }
    useEffect(() => {
        dispatch(changeCurrentTest(1))
        dispatch(createTest(testInitConfig))

    }, [])
    if(!localStorage.getItem('access_token_teacher')){
        return <Navigate  to={'/teacherLogin'}/>
    }
    return (
        <>
            {/* <SearchHeader /> */}
            <Header/>
            <div className={style.cont}>
                {currentTest ?
                <>
                  <FormInput type="text" labelText="Введите название теста" value={currentTest.title} onChange={(e) => dispatch(setTitleQuest(e.target.value))} />
                  <FormInput type="number" labelText="Проходной процент" value={currentTest.passing_score} onChange={(e) => dispatch(setPassingScore(e.target.value))} />
                  <FormInput type="number" labelText="Макс баллов" value={currentTest.max_score} onChange={(e) => dispatch(setMaksScore(e.target.value))} />
                <br />
                <br />
                <FormInput type="text" labelText="Класс которому предназначен тест" value={currentTest.for_class} onChange={(e) => dispatch(setClass(e.target.value))} />

                </>
              
             :''} </div>

            <div className={style.cont}>
                <AddedTest />
                {
                    currentTest ? <CreateTests /> : ''
                }
            </div>
        </>
    );
}

