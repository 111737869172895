import { FC, useState } from "react";
import classes from './TestManyChoice.module.scss';
import { ChoiceCard } from "../../UI";
import { useSelector,useDispatch } from "react-redux";
import { createAnswer } from "../../../store/slices/createTest";
interface TestFormProps {
    question: string | null;
}

export const TestManyChoice: FC<TestFormProps> = ({ question}) => {
    const dispatch = useDispatch()
    const [selectedCards, setSelectedCards] = useState<number[]>([]);
    const [isAddedQuest, setIsAddedQuest] = useState<boolean>(false);
    const currentTest = useSelector((state:any)=>state.createTest.currentTest)
    const currentTestId = useSelector((state:any)=>state.createTest.currentTestId)
    const [newQuestion, setNewQuestion] = useState<string>('');
    const [cards,setCards] = useState([{text: 'newQuestion',
        id: 1
    }])
    const handleSelectCard = (id: number) => {
        setSelectedCards(prevSelected => 
            prevSelected.includes(id) 
            ? prevSelected.filter(cardId => cardId !== id) 
            : [...prevSelected, id]
        );
    };
    const handleAddQuestion = () => {
        if (newQuestion.trim() !== '') {
            console.log(currentTestId);
            
            const idx = currentTestId-1
            const answerId = currentTest.questions[idx].answers.length>0? currentTest.questions[idx].answers.length+1:1
            
            const payload = {
                idx,
                answer:{
                    id:answerId,
                    answer_text:newQuestion,
                    is_active:false
                }
            }
            dispatch(createAnswer(payload))
            setNewQuestion('');
            setIsAddedQuest(false);
        }
        // if (newQuestion.trim() !== '') {
        //     const newQuestionObj = {
        //         id: cards.length + 1,
        //         text: newQuestion,
        //         index: cards.length
        //     };

        //     setCards([...cards, newQuestionObj]);
        //     setNewQuestion('');
        //     setIsAddedQuest(false);
        // }
    };
    return (
        <section className={classes.section}>
          {
            question !== '' ? (
              <h2>{question}</h2>
            ) : ''
          }
          <div className={classes.cardContainer}>
              {currentTest.questions[currentTestId-1].answers.map(({ id, answer_text }:any,index:number) => (
                  <ChoiceCard
                      key={index} 
                      id={id} 
                      text={answer_text} 
                      onSelect={handleSelectCard} 
                      questionArr={cards}
                      setQuestionArr={setCards}
                      isSelected={selectedCards.includes(id)} 
                  />
              ))}
          </div>
            <button onClick={() => setIsAddedQuest(true)} className={classes.btnAdd}>добавить</button>
            {isAddedQuest && (
                <div className={classes.choiceAdded}>
                    <input
                        type="text"
                        value={newQuestion}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewQuestion(e.target.value)}
                    />
                    <button onClick={() => setIsAddedQuest(false)}>закрыть</button>
                    <button onClick={handleAddQuestion}>добавить</button>
                </div>
            )}
      </section>
    );
}
