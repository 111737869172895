import React from 'react'

const Error = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_330_379)'>
        <path
          d='M15.9986 30.546C17.9091 30.5485 19.8012 30.1734 21.5663 29.4422C23.3314 28.7111 24.9345 27.6383 26.2837 26.2856C27.6364 24.9365 28.7091 23.3333 29.4403 21.5683C30.1714 19.8032 30.5465 17.911 30.544 16.0005C30.5465 14.0901 30.1714 12.1979 29.4402 10.4329C28.7091 8.6678 27.6364 7.06462 26.2837 5.71545C24.9345 4.36275 23.3314 3.29 21.5663 2.55886C19.8012 1.82772 17.9091 1.45261 15.9986 1.45509C14.0881 1.45265 12.196 1.82778 10.4309 2.55892C8.66585 3.29005 7.06267 4.36278 5.7135 5.71545C4.36083 7.06462 3.2881 8.6678 2.55696 10.4329C1.82583 12.1979 1.4507 14.0901 1.45314 16.0005C1.45066 17.911 1.82577 19.8032 2.55691 21.5683C3.28805 23.3333 4.36079 24.9365 5.7135 26.2856C7.06267 27.6383 8.66585 28.711 10.4309 29.4422C12.196 30.1733 14.0881 30.5484 15.9986 30.546Z'
          stroke='#FF0000'
          stroke-width='2.90909'
          stroke-linejoin='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M15.9979 25.4547C16.4801 25.4547 16.9425 25.2632 17.2835 24.9222C17.6245 24.5812 17.8161 24.1188 17.8161 23.6365C17.8161 23.1543 17.6245 22.6919 17.2835 22.3509C16.9425 22.0099 16.4801 21.8184 15.9979 21.8184C15.5157 21.8184 15.0532 22.0099 14.7122 22.3509C14.3712 22.6919 14.1797 23.1543 14.1797 23.6365C14.1797 24.1188 14.3712 24.5812 14.7122 24.9222C15.0532 25.2632 15.5157 25.4547 15.9979 25.4547Z'
          fill='#FF0000'
        />
        <path
          d='M16 7.27344V18.9098'
          stroke='#FF0000'
          stroke-width='2.90909'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_330_379'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Error
