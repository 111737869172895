import { FC,useState } from "react";
import { SearchHeader } from "../../components/CreateTest";
import { TestSpartTable } from "../../components";
import style from './TestSpart.module.scss';
import { Navigate } from "react-router-dom";
export const TestSpart: FC = () => {

    const [searchTerm, setSearchTerm] = useState<string>('');

    if(!localStorage.getItem('access_token_teacher')){
        return <Navigate  to={'/teacherLogin'}/>
    }   

    return (
        <div className={style.cont}>
            <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <TestSpartTable searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
        </div>
    );
}
