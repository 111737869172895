import React, { FC } from 'react';
import { deleteAnswer } from '../../../../store/slices/createTest';
import { useDispatch, useSelector } from 'react-redux';
import classes from './TestVideoCard.module.scss';

interface TestVideoCardProps {
    id: number;
    imgURL: string;
    onSelect: (id: number) => void;
    isSelected: boolean;
    setQuestionArr:any;
    questionArr:any
}

const TestVideoCard: FC<TestVideoCardProps> = ({ id,  imgURL, onSelect, isSelected,questionArr ,setQuestionArr }) => {

    const dispatch = useDispatch()
    const currentTestId = useSelector((state: any) => state.createTest.currentTestId)

    const handleClick = () => {
        onSelect(id);
    };

    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(deleteAnswer({
        idx:currentTestId-1,
        answerId:id
    }))
    };

    return (
        <div className={`${classes.card} ${isSelected ? classes.selected : ''}`} onClick={handleClick}>
            <div className={classes.circle}>
                <span>{id}</span>
            </div>
            <video src={imgURL} controls />
            <div className={classes.delete} onClick={handleDelete}>delete</div>

        </div>
    );
}

export default TestVideoCard;
