const True = () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 7.00015L2.10261 4.90375L6.30782 9.09655L14.7182 0.710938L16.8209 2.80734L6.30782 13.2894L0 7.00015Z"
        fill="black"
      />
    </svg>
  );
};

export default True;
