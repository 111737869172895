import { FC } from 'react';
import style from './ResultMark.module.scss';
import { Progress } from 'antd';
import { useMediaQuery } from '../../../hooks/mediaQuery';
import Item from 'antd/es/list/Item';

interface ResultMarkProgress {
    progress: number;
    result_answers?: any
}

export const ResultMark: FC<ResultMarkProgress> = ({ progress, result_answers }) => {
    const w = useMediaQuery();
    console.log(result_answers, 'res');

    return (
        <div className={style.cont}>
            <div className={style.studentName}>
                <div className={style.names}>
                    <p><b>Студент:</b>{result_answers.result ? result_answers.result.student.full_name : result_answers.student.full_name}</p>
                    <p><b>Кл.преподователь:</b>{result_answers.result ? result_answers.result.test.teacher : result_answers.test.teacher}</p>
                    <p><b>Класс:</b>{result_answers.student?result_answers.student.completed_class: result_answers.result.student.completed_class}</p>
                </div>
                <div className={style.progresMark}><Progress type="circle" percent={progress} size={w > 1200 ?  290 : w < 700 ? 105 : 200} strokeColor={progress >= 75 ? '#248B00' : progress < 75 && progress >= 50 ? '#C68810' : '#AF251C'} /></div>
            </div>

            <div className={style.questionsCont}>
                <h2>Вопросы:</h2>

                <div className={style.questions}>
                    {
                        result_answers.result ?
                            result_answers.result.result_answers.map((item: any) => {
                                console.log('item', item);

                                return <p key={item.id} className={`${style.answer} ${item.answer.is_active === true ? style.correct : ''}`}>{item.id}) {item.question.questions}</p>
                            })
                            :
                            result_answers.result_answers.map((item: any) => {
                                console.log('item', item);

                                return <p key={item.id} className={`${style.answer} ${item.answer.is_active === true ? style.correct : ''}`}>{item.id}) {item.question.questions}</p>
                            })
                    }
                </div>
            </div>
        </div>
    );
}

