import { FC } from "react";
import classes from './ChoiceCard.module.scss'
import { useSelector,useDispatch } from "react-redux";
import { deleteAnswer } from "../../../store/slices/createTest";
import { selectTrueAnswer } from "../../../store/slices/createTest";
interface QuestionCardProps {
  id: number;
  text: string;
  onSelect: (id: number) => void;
  isSelected: boolean;
  questionArr?: any
  setQuestionArr?: any
}

const ChoiceCard: FC<QuestionCardProps> = ({ id, text, onSelect, isSelected, questionArr, setQuestionArr }) => {
  const dispatch = useDispatch()
  const currentTestId = useSelector((state:any)=>state.createTest.currentTestId)

  const handleClick = () => {
    dispatch(selectTrueAnswer({
      idx: currentTestId - 1,
      answerId: id,
      type: 'checkbox'
  }))
    onSelect(id);
  };
  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(deleteAnswer({
      idx:currentTestId-1,
      answerId:id
  }))
  };

  return (
    <div className={`${classes.card} ${isSelected ? classes.selected : ''}`} onClick={handleClick}>
      <p className={`${classes.text} ${isSelected ? classes.selected : ''}`}>{text}</p>
      <div className={`${classes.circle} ${isSelected ? classes.selected : ''}`}>
        <span>{id}</span>
      </div>
      <div className={classes.delete} onClick={handleDelete}>delete</div>
    </div>
  );
}

export default ChoiceCard;
