import React from "react";

const Open = () => {
  return (
    <svg
      className="svg-icon"
      style={{
        width: "25",
        height: "35",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden",
      }}
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M512 224.064a32 32 0 0 0-32 32v65.008a490.8 490.8 0 0 0-107.168 18.624L347.2 277.792a32.016 32.016 0 0 0-59.136 24.496l24.512 59.184a507.936 507.936 0 0 0-81.12 44.848l-48.832-48.832a31.936 31.936 0 0 0-45.248 0 32 32 0 0 0 0 45.248l42.976 42.976a551.568 551.568 0 0 0-43.92 42.704 31.776 31.776 0 0 0-8.304 23.616 31.92 31.92 0 0 0 8.304 23.616C236.064 644.208 368.848 704 510.32 704c142.448 0 276.432-60.496 377.264-170.352 0.32-0.352 0.544-0.768 0.848-1.12a29.056 29.056 0 0 0 3.648-5.376 28.72 28.72 0 0 0 2.448-5.888 29.296 29.296 0 0 0 1.216-6.128 33.6 33.6 0 0 0 0-6.208 28.096 28.096 0 0 0-1.216-6.128 30.56 30.56 0 0 0-6.096-11.264c-0.304-0.368-0.512-0.768-0.848-1.12a560.352 560.352 0 0 0-44.96-43.664l44-44a32 32 0 1 0-45.248-45.248L791.52 407.36a512.08 512.08 0 0 0-83.728-46.16l24.4-58.896a32 32 0 0 0-59.136-24.496l-25.568 61.712A493.424 493.424 0 0 0 544 321.344v-65.28a32 32 0 0 0-32-32zM510.32 640c-114.528 0-222.72-45.28-307.552-127.968 84.832-82.688 193.024-127.968 307.552-127.968 114.624 0 223.184 45.28 308.832 127.968C733.504 594.72 624.944 640 510.32 640z" />
      <path d="M512 480.064c17.648 0 32 14.352 32 32s-14.352 32-32 32-32-14.352-32-32 14.352-32 32-32m0-64a96 96 0 1 0 0 192 96 96 0 0 0 0-192z" />
    </svg>
  );
};

export default Open;
