import { FC, useEffect, useState } from 'react'
import classes from './testform.module.scss'
import { TestComponent, TestHeader } from '../../components/Tests'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addResultTest,initTest,setTestResult } from "../../store/slices/TestSlice";
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { setTestLocation } from '../../store/slices/authSlice';
// import { useSelector } from 'react-redux';
const TestForm: FC = () => {
    // const [selectedCard, setSelectedCard] = useState<number | null>(null);

    // const handleSelectCard = (id: number) => {
    //     setSelectedCard(id);
    //     sendSelectionToServer(id);
    // };

    // Default arrays !!!
    const {id} = useParams()
    const answersRes = useSelector((state: any) => state.test)
    const [tests, setTests] = useState<any>({});
    const [answers, setAnswers] = useState<any>([]);
    const [sendData,setSendData] = useState({})
    const {loaction_test} = useSelector((state:any)=>state.auth)
    console.log(loaction_test,'loaction_testloaction_testloaction_testloaction_testloaction_testloaction_testloaction_testloaction_test');
    
    
    const dispatch = useDispatch()
    const naviagate = useNavigate()

    console.log(answersRes, 'answersRes');

    useEffect(() => {
        // console.log(loaction_test);
        const enrollee_id = JSON.parse(localStorage.getItem('current_enrollee_test_id')!)
        const enrollee_data = JSON.parse(localStorage.getItem('current_enrollee_data')!)
        console.log(enrollee_id,'enrollee_id');
        console.log(enrollee_data,'enrollee_data');
        console.log('https://yiman.webtm.ru/api/v1/tests/'+(enrollee_id?enrollee_id:id));
        
        fetch('https://yiman.webtm.ru/api/v1/tests/'+(enrollee_id?enrollee_id:id) +'/', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        }).then(async(res) => {
            // dispatch(setTestLocation(loaction_test+1))
            console.log(res);
            if(!res.ok){
                alert('Похоже вам надо перезайти')
                naviagate('/login')
            }

            console.log(res,'rescaaa');
            // const asasasa = await res.json()
            // console.log(asasasa,'dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
            
            return res.json()
        }).then((data) => {

            // setQuestions(data)
            console.log(data);
            setTests(data)   
            dispatch(initTest({
                "student": {
                    "full_name": "string",
                    "first_name": "string",
                    "middle_name": "string",
                    "last_name": "string",
                    "email": "user@example.com",
                    "student_phone": "string"
                  },
                test: {
                    id:data.id,
                    title: data.title,
                    choise: data.choise,
                    teacher: data.teacher,
                    questions:[],
                }
            }))
        })

    }, [])
    console.log('ascasc', tests);
    console.log(sendData,'sendData');
    if(!localStorage.getItem('access')){
        return <Navigate  to={'/login'}/>
    }
    return (
        <div className={classes.container}>
            <TestHeader title={tests.title} />
            {
                tests.questions?.map((question: any) => (
                    <TestComponent test={question} testAllData={tests} setAnswers={setAnswers} answers={answers} />
                ))
            }

            <section className={classes.section}>
                <button className={classes.primaryButton} onClick={async () => {
                    // console.log({
                    //     test:test.id,
                    //     result_answers:result
                    // });
                    // console.log({
                    //     answers:result
                    // });
                    console.log(localStorage.getItem('access'));
                    
                    console.log(answersRes, 'answersRes');
                    console.log(answersRes.test, 'answersRes.test');
                    console.log(JSON.stringify(answersRes.test), 'answersRes.test');

                    const res = await fetch(`https://yiman.webtm.ru/api/v1/tests/${id}/take/`, {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('access')}`
                        },
                        body: JSON.stringify(answersRes.test)
                    })
                    console.log(res);
                    const data = await res.json()
                    console.log(data);
                   
                    if(res.ok){ 
                        const enrollee_id = JSON.parse(localStorage.getItem('current_enrollee_test_id')!)
                        const enrollee_data = JSON.parse(localStorage.getItem('current_enrollee_data')!)
                        const idx = enrollee_data.findIndex((item:any)=>item.id===enrollee_id)
                        console.log(idx,'idx idx');
                        console.log(idx>0,'idx idx');
                        if(idx>-1){
                            console.log('idx>0');
                            
                            if(enrollee_data[3].id===enrollee_id){
                            console.log('aaa');

                                localStorage.setItem('current_enrollee_test_id',JSON.stringify(undefined!))
                            }else{
                                console.log(enrollee_data[idx+1].id);
                                
                                localStorage.setItem('current_enrollee_test_id',JSON.stringify(enrollee_data[idx+1].id))
                            }
                        }
                        dispatch(setTestResult(data))
                        naviagate('/finishTest')
                    }
                }}>Отправить</button>
            </section>

        </div>
    );
}

export default TestForm;