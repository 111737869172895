import React from 'react'
import classes from './TestVideoCardBig.module.scss'
interface Props{
    url:string
}
const TestVideoCardBig:React.FC<Props> = ({url}) => {
  return (
    <div className={classes.testVideoCardBig}>
        <video src={'https://yiman.webtm.ru'+url} controls></video>
    </div>
  )
}

export default TestVideoCardBig