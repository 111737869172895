const Important = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_689_1027)">
        <path
          d="M12.5 0.5C10.0277 0.5 7.61099 1.23311 5.55538 2.60663C3.49976 3.98015 1.89761 5.93238 0.951511 8.21646C0.00541608 10.5005 -0.242126 13.0139 0.24019 15.4386C0.722505 17.8634 1.91301 20.0907 3.66117 21.8388C5.40933 23.587 7.63661 24.7775 10.0614 25.2598C12.4861 25.7421 14.9995 25.4946 17.2835 24.5485C19.5676 23.6024 21.5199 22.0002 22.8934 19.9446C24.2669 17.889 25 15.4723 25 13C24.9964 9.68589 23.6783 6.50855 21.3349 4.16512C18.9915 1.8217 15.8141 0.503584 12.5 0.5ZM12.5 23.4167C10.4398 23.4167 8.42583 22.8057 6.71282 21.6611C4.9998 20.5165 3.66467 18.8897 2.87626 16.9863C2.08785 15.0829 1.88156 12.9884 2.28349 10.9678C2.68542 8.94718 3.67751 7.0911 5.13431 5.6343C6.59111 4.17751 8.44718 3.18542 10.4678 2.78349C12.4885 2.38156 14.5829 2.58784 16.4863 3.37626C18.3897 4.16467 20.0165 5.4998 21.1611 7.21281C22.3057 8.92582 22.9167 10.9398 22.9167 13C22.9136 15.7617 21.8152 18.4095 19.8624 20.3623C17.9095 22.3152 15.2617 23.4136 12.5 23.4167Z"
          fill="black"
        />
        <path
          d="M12.4997 5.7085C12.2234 5.7085 11.9585 5.81824 11.7631 6.01359C11.5678 6.20894 11.458 6.4739 11.458 6.75016V15.0835C11.458 15.3598 11.5678 15.6247 11.7631 15.8201C11.9585 16.0154 12.2234 16.1252 12.4997 16.1252C12.7759 16.1252 13.0409 16.0154 13.2362 15.8201C13.4316 15.6247 13.5413 15.3598 13.5413 15.0835V6.75016C13.5413 6.4739 13.4316 6.20894 13.2362 6.01359C13.0409 5.81824 12.7759 5.7085 12.4997 5.7085Z"
          fill="black"
        />
        <path
          d="M13.5413 19.2502C13.5413 18.6749 13.075 18.2085 12.4997 18.2085C11.9244 18.2085 11.458 18.6749 11.458 19.2502C11.458 19.8255 11.9244 20.2918 12.4997 20.2918C13.075 20.2918 13.5413 19.8255 13.5413 19.2502Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_689_1027">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Important;
