import React from "react";
import classes from "./formInput.module.scss";
import Open from "./icons/Open";
import Close from "./icons/Close";

interface Props {
  type?: string;
  labelText?: string;
  id?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  error?: string;
  touched?: boolean;
  name?: string;
  minlength?: any;
  className?: string;
}

const FormInput: React.FC<Props> = ({
  type = "text",
  labelText,
  name,
  id,
  value,
  className,
  minlength,
  ...props
}) => {
  const [isShow, setIsShow] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  console.log(name ? name : "");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <label
      className={`${classes.form_field} ${className}`}
      htmlFor={`form_${labelText}`}
    >
      <input
        {...props}
        type={type === "password" && showPassword ? "text" : type}
        className={`${classes.form_item}`}
        id={id ? id : ""}
        value={value ? value : ""}
        name={name ? name : ""}
        minLength={minlength ? minlength : ""}
        onInput={() => setIsShow(true)}
        onBlur={(e: any) => {
          if (e.target.value.length === 0) {
            setIsShow(false);
          } else {
            setIsShow(true);
          }
        }}
        onFocus={() => setIsShow(true)}
      />
      <span style={{ display: isShow ? "none" : "block" }}>{labelText}</span>
      {type === "password" && (
        <button
          type="button"
          className={classes.toggle_password}
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <Open /> : <Close />}
        </button>
      )}
    </label>
  );
};

export default FormInput;
