import { FC } from "react";
import style from './AddedTest.module.scss';
import { AddedTestCard } from "./AddedTestCard/AddedTestCard";
import { useSelector, useDispatch } from "react-redux";
import { createQuestion,setCurrentTestId ,clearFiles} from "../../../store/slices/createTest";
import { useMediaQuery } from "../../../hooks/mediaQuery";

export const AddedTest: FC = () => {
    const dispatch = useDispatch()
    const currentTest = useSelector((state: any) => state.createTest.currentTest)
    const currentTestId = useSelector((state: any) => state.createTest.currentTestId)
    // console.log('curr', currentTest);

    function addQuestion() {
        dispatch(clearFiles({idx:currentTestId-1}))
        if (currentTest.questions.length !== 0) {
            // console.log(currentTest.questions.length);

            dispatch(createQuestion({
                id: currentTest.questions.length+1, 
                questions: '',
                type:'text',
                files:[],
                files_count:0,
                files_type:'photo',
                answers: [
                    // {
                    //     id: 1,
                    //     answer: '',
                    //     is_active: false
                    // }
                ]
            }))
            // dispatch(setCurrentTestId(currentTest.questions.length))

        } else {
            // console.log(currentTest.questions.length);

            dispatch(createQuestion({
                id: 1,
                type:'text',
                files:[],
                questions: '',
                files_count:0,
                files_type:'photo',
                answers: [
                    // {
                    //     id: 1,
                    //     answer: '',
                    //     is_active: false
                    // }
                ]
            }))
            // dispatch(setCurrentTestId(1))

        }
    }
    function objectToFormData(obj:any, formData:any) {
    formData = formData || new FormData();

    Object.keys(obj).forEach(key => {
        if (Array.isArray(obj[key])) {
            // Если элемент массив, то обрабатываем каждый его элемент
            obj[key].forEach((item:any, index:any) => {
                objectToFormData(item, formData);
            });
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            // Если элемент объект, то рекурсивно обрабатываем его
            objectToFormData(obj[key], formData);
        } else {
            // Если простое значение, то добавляем его в formData
            formData.append(key, obj[key]);
        }
    });

    return formData;
}
    const saveTest =async ()=>{
        const formData = new FormData()
        const formDataRes = objectToFormData(currentTest,formData)
        // console.log(formDataRes);
        
        formData.append('title',currentTest.title)
        // console.log(currentTest);
        console.log(JSON.stringify(currentTest));
        console.log(currentTest);
        
        const res = await fetch('https://yiman.webtm.ru/api/v1/tests/create/',{
            method:'POST',
            headers:{
                'Content-type':'application/json',
                 Authorization: `Bearer `+localStorage.getItem('access_token_teacher')
            },
            body:JSON.stringify(currentTest)
        })
        // const datauiu =await res.json()
            // console.log(datauiu);
        

        // console.log(res);
        if(res.ok){
            alert('Тест успешно создан!')
        }else{
            alert('Вы не заполнили все поля')
        }
        const data = await res.json()
        // console.log(data);
        

    }

    return (
        <div className={style.cont}>
            <div className={style.button}>
                <button onClick={addQuestion}>Добавить вопрос</button>
            </div>
            {
                currentTest &&
                currentTest?currentTest.questions?.map((item:any)=>{
                    console.log(item);
                    return <AddedTestCard id={item.id} title={item.questions}/>
                }):''
            }
            <div className={style.button}>
                <br />
                <button onClick={saveTest}>Сохранить тест</button>
            </div>
                


            {/* <AddedTestCard id={1} title="Какая из следующих характеристик является признаком рыночной экономики?"/> */}
        </div>
    );
}

