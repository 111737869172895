import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classes from './LoginStudent.tsx.module.scss';
import FormInput from '../../components/UI/FormInput/FormInput'; 
import googleIcon from '../../../assets/icons/googleIcon.svg';
import { api } from '../../api';
import { useNavigate } from 'react-router-dom';
interface Props{
  messageApi:any
}

const LoginStudent:React.FC= () => {
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: '',
      password:''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        // .email('Не верная электронная почта')
        .required('Поле обязательно для заполнения'),
    }),
    onSubmit:async (values) => {
      const res = await fetch('https://yiman.webtm.ru/api/v1/users/login_student/',{
        method:'POST',
        headers:{
          'Content-type':'application/json'
        },
        body:JSON.stringify({
          email:values.email,
          student_password:values.password,
        })
      })
      console.log(res);
      const data = await res.json()
      console.log('data',data);
      if(res.ok){
        console.log('l');
        localStorage.setItem('access',data.access_token)
        localStorage.setItem('refresh',data.refresh_token)
        localStorage.setItem('student_id',data.id)
   
        navigate('/student/studentProfile')
      }else{
       alert('Похоже вы не зарегистрированы ')
      }
    },
  });

  return (
    <div className={classes.teacherLogin}>

    <div className={classes.LoginStudent}>
      <h1 className={classes.form_title}>Вход для учеников</h1>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        {formik.touched.email && formik.errors.email ? (
          <p className={classes.error}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
              <g clipPath="url(#clip0_243_5822)">
                <path d="M12.9998 24.8187C14.5521 24.8208 16.0895 24.516 17.5236 23.9219C18.9577 23.3279 20.2603 22.4563 21.3565 21.3572C22.4555 20.261 23.3272 18.9584 23.9212 17.5243C24.5152 16.0902 24.82 14.5528 24.818 13.0006C24.82 11.4483 24.5152 9.91093 23.9212 8.47682C23.3271 7.04271 22.4555 5.74013 21.3565 4.64393C20.2603 3.54485 18.9577 2.67325 17.5236 2.0792C16.0895 1.48515 14.5521 1.18037 12.9998 1.18238C11.4476 1.1804 9.91019 1.4852 8.47609 2.07924C7.04198 2.67329 5.7394 3.54488 4.6432 4.64393C3.54415 5.74013 2.67256 7.04271 2.07851 8.47682C1.48446 9.91093 1.17967 11.4483 1.18165 13.0006C1.17964 14.5528 1.48442 16.0902 2.07846 17.5243C2.67251 18.9584 3.54412 20.261 4.6432 21.3572C5.7394 22.4562 7.04198 23.3278 8.47609 23.9219C9.91019 24.5159 11.4476 24.8207 12.9998 24.8187Z" stroke="#FF0000" strokeWidth="2.36364" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.9988 20.6821C13.3906 20.6821 13.7663 20.5264 14.0433 20.2494C14.3204 19.9724 14.476 19.5966 14.476 19.2048C14.476 18.813 14.3204 18.4373 14.0433 18.1602C13.7663 17.8832 13.3906 17.7275 12.9988 17.7275C12.607 17.7275 12.2312 17.8832 11.9542 18.1602C11.6771 18.4373 11.5215 18.813 11.5215 19.2048C11.5215 19.5966 11.6771 19.9724 11.9542 20.2494C12.2312 20.5264 12.607 20.6821 12.9988 20.6821Z" fill="#FF0000"/>
                <path d="M13 5.90967V15.3642" stroke="#FF0000" strokeWidth="2.36364" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_243_5822">
                  <rect width="26" height="26" fill="white"/>
                </clipPath>
              </defs>
            </svg> 
            {formik.errors.email}
          </p>
        ) : null}
        <FormInput
          id="email"
          name="email"
          labelText="Электронная почта"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.email}
        />
        <FormInput
          id="password"
          name="password"
          type="password"
          minlength='8'
          labelText="Пароль"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.password}
        />  
        <button type="submit" className={classes.googelBtn}>
          Получить 
        </button>
      </form>
    </div>
    </div>

  );
};

export default LoginStudent;
