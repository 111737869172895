import { FC } from "react";
import classes from './ChoiceCard.module.scss';

interface ChoiceCardProps {
    id: number;
    text: string;
    onSelect: any;
    isSelected: boolean;
    questionArr?: any
    setQuestionArr?:any
    type?:any
}

export const ChoiceCard: FC<ChoiceCardProps> = ({id, text, onSelect, isSelected, questionArr, setQuestionArr,type,...props}) => {
    const handleClick = () => {
      onSelect(id);
    };
    console.log(text);
    
    return (
        <div className={`${classes.card} ${isSelected ? classes.selected : ''}`} onClick={handleClick}>
            <p className={`${classes.text} ${isSelected ? classes.selected : ''}`}>{text}</p>
            <div className={`${classes.circle} ${isSelected ? classes.selected : ''}`}>
            <span>{id}</span>
            </div>
        </div>
    );
}

