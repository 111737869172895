import { FC, useEffect,useState } from "react";
import { ArchiveHeader } from "../../components/TestArchives";
import style from './TestArchive.module.scss';
import { ResultMark } from "../../components/TestResult";

export const TestArchive: FC = () => {
    const [data,setData] = useState([])
    const [button, setButton] = useState<number | null>(1);

    useEffect(()=>{
        fetch('https://yiman.webtm.ru/api/v1/tests/results/',{
            headers:{
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        }).then(async(res)=>{
            const data = await res.json()
            console.log(data);
            setData(data)
        })
    },[])
    return (
        <div className={style.container}>
            <ArchiveHeader button={button} setButton={setButton}/>
            {
                data?.map((item:any)=>{
                    if(button===1 && item.test.choise ==="1-четверть"){
                        return  <ResultMark progress={(item.score)} result_answers={item} />
                    }
                    if(button===2 && item.test.choise ==="2-четверть"){
                        return  <ResultMark progress={(item.score)} result_answers={item} />
                    }
                    if(button===3 && item.test.choise ==="3-четверть"){
                        return  <ResultMark progress={(item.score)} result_answers={item} />
                    }
                    if(button===4 && item.test.choise ==="4-четверть"){
                        return  <ResultMark progress={(item.score)} result_answers={item} />
                    }
                })
            }
        </div>
    );
}

