import { LeftOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import styles from "./ArchiveHeader.module.scss";
import { Link } from "react-router-dom";
import { useEffect } from "react";
interface Props {
  button: any;
  setButton: any;
}
export const ArchiveHeaderAbut: FC<Props> = ({ button, setButton }) => {
  const [data,setData] = useState([])
  const buttonClick = (id: number) => {
    setButton(id);
  };
  useEffect(()=>{
    fetch('https://yiman.webtm.ru/api/v1/tests/tests_result/',{
        headers:{
            Authorization: `Bearer ${localStorage.getItem('access')}`
        }
    }).then(async(res)=>{
        const data = await res.json()
        console.log(data);
        setData(data)
    })
},[])
  return (
    <div className={styles.header}>
    
        <h2>
          <Link to={"/student/studentProfile"}>
            <span>
              <LeftOutlined />
            </span>
          </Link>{" "}
          Тесты абитуриента
        </h2>
    

      <div className={styles.buttons}>
        {/* <div className={button === 1 ? styles.greenButton : styles.button}>
          <button onClick={() => buttonClick(1)}>тест 1</button>
        </div>
        <div className={button === 2 ? styles.greenButton : styles.button}>
          <button onClick={() => buttonClick(2)}>тест 2</button>
        </div>
        <div className={button === 3 ? styles.greenButton : styles.button}>
          <button onClick={() => buttonClick(3)}>тест 3</button>
        </div>
        <div className={button === 4 ? styles.greenButton : styles.button}>
          <button onClick={() => buttonClick(4)}>тест 4</button>
        </div> */}
        {
          data?
          data.map((item:any,index:any)=>{
            return<div className={button === (index+1) ? styles.greenButton : styles.button}>
            <button onClick={() => buttonClick(index+1)}>{item.test.title}</button>
          </div>
          })
          :''
        }
      </div>
    </div>
  );
};
